/* eslint-disable react-hooks/exhaustive-deps */
import React, { startTransition, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateOrganization } from "../../../redux/organizationSlice";
import { CaretRightOutlined, InfoCircleFilled } from "@ant-design/icons";
import { Button, Collapse, Input, Modal, Spin, theme, Typography, message, Divider, Select, Radio, Alert } from "antd";
import * as yup from "yup";
import { MAX_ALLOWED_QUESTIONS } from "../../../utils/constants";
import { Option } from "antd/es/mentions";
import AnswerTag from "./tags";

const { Title } = Typography;

const questionSchema = yup.object().shape({
  question: yup.string().trim().required("Question cannot be empty."),
  options: yup
    .array()
    .of(
      yup.object().shape({
        option: yup.string().trim().required("Option cannot be empty."),
      })
    )
    .min(2, "At least two option is required.")
    .test(
      "unique-options",
      "Options must be unique.",
      (options) => new Set(options.map(option => option.option.trim())).size === options.length
    ),
});

const InitialQuestionData = {
  question: "",
  options: [],
  selectedTrade: "Plumbing",
  selectedServiceType: "repair",
  associateJobs: {
    Plumbing: {
      repair: [],
      maintenance: [],
      estimate: []
    },
    Electrical: {
      repair: [],
      maintenance: [],
      estimate: []
    },
    "Heating & Cooling": {
      repair: [],
      maintenance: [],
      estimate: []
    },
    
  },
  combineJobs: []
}

export default function Questions() {

  const dispatch = useDispatch();
  const { token } = theme.useToken();

  const { organizationData, loading } = useSelector((state) => state.organization);
  const { jobs = [] } = useSelector((state) => state.jobs);
  const { trades, services } = useSelector((state) => state.trade);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [questionData, setQuestionData] = useState(InitialQuestionData);
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [action, setAction] = useState("Add");

  const showModal = (question) => {

    const { associateJobs } = question;
    const formattedAssociateJobs = {};

    associateJobs.forEach((jobData) => {
      const { trade, serviceType, job } = jobData;
      const tradeName = trade?.tradeName
      if (!formattedAssociateJobs[tradeName]) {
        formattedAssociateJobs[tradeName] = {};
      }
      serviceType.forEach((type) => {
        if (!formattedAssociateJobs[tradeName][type.toLowerCase()]) {
          formattedAssociateJobs[tradeName][type.toLowerCase()] = [];
        }
        formattedAssociateJobs[tradeName][type.toLowerCase()]?.push(job);
      });
    });


    setQuestionData({
      question: question.question,
      options: question.options || [],
      selectedTrade: "Plumbing",
      selectedServiceType: "repair",
      associateJobs: { ...InitialQuestionData.associateJobs, ...formattedAssociateJobs },
      combineJobs: question.associateJobs
    });
 
    setCurrentQuestionId(question._id);
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    try {
      await questionSchema.validate(questionData);
      if (action === "Add") {
        const updatedOrganizationData = {
          ...organizationData,
          questions: [...organizationData.questions, { ...questionData, associateJobs: questionData.combineJobs }],
        };
        dispatch(updateOrganization({ id: organizationData._id, payload: updatedOrganizationData,page: "questions" }));
        setIsModalOpen(false);
        return;
      }

      const questionIndex = organizationData.questions.findIndex((e) => e._id === currentQuestionId);
      const updatedOrganizationData = {
        ...organizationData,
        questions: organizationData.questions.map((question, index) => {
          if (index !== questionIndex) return question;
          return {
            ...question,
            question: questionData.question,
            options: questionData.options,
            associateJobs: questionData.combineJobs,
          };
        }),
      };

      dispatch(updateOrganization({ id: organizationData._id, payload: updatedOrganizationData,page: "questions" }));
      setIsModalOpen(false);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        error.errors.forEach((err) => message.error(err));
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const addAnswer = () => {
    setQuestionData((prev) => ({
      ...prev,
      options: [...prev.options, { option: "" }],
    }));
  };

  const deleteQuestion = (questionId) => {
    const updatedQuestions = organizationData.questions.filter((question) => question._id !== questionId);
    const updatedOrganizationData = {
      ...organizationData,
      questions: updatedQuestions,
    };
    dispatch(updateOrganization({ id: organizationData._id, payload: updatedOrganizationData,page: "questions" }));
  }

  const removeAnswer = (index) => {
    const updatedOptions = questionData.options.filter((_, i) => i !== index);
    setQuestionData((prev) => ({
      ...prev,
      options: updatedOptions,
    }));
  };

  const optionHandler = (e, index) => {
    const updatedOptions = questionData.options.map((option, i) =>
      i === index ? { ...option, option: e.target.value } : option
    );
    setQuestionData((prev) => ({
      ...prev,
      options: updatedOptions,
    }));
  };

  const getAssociatedQuestions = () => {
    const Questions = [];
    organizationData?.questions?.forEach((question) => {
      const questionObj = {
        key: question?.question,
        label: question?.question,
        children: (
          <AnswerTag deleteQuestion={deleteQuestion}  showModal={showModal} setAction={setAction} question={question} />
        ),
        style: {
          marginBottom: 10,
          background: token.colorFillAlter,
          borderRadius: token.borderRadiusLG,
          border: "none",
          fontSize: "16px",
        },
      };
      Questions.push(questionObj);
    });
    return Questions;
  };

  function reduceBackendArray(backendArray) {
    const reducedData = {};
    backendArray?.forEach(job => {
      const { trade, serviceType, jobItem } = job;
      const { tradeName } = trade;

      if (!reducedData[tradeName]) {
        reducedData[tradeName] = {};
      }

      if (!reducedData[tradeName][serviceType]) {
        reducedData[tradeName][serviceType] = [];
      }

      reducedData[tradeName][serviceType].push(jobItem);
    });

    return reducedData;
  }

  const jobSelection = (selectedJobs) => {
    let { selectedTrade, selectedServiceType, associateJobs, combineJobs } = questionData;

    let updatedAssociateJobs = {
      ...associateJobs,
      [selectedTrade]: {
        ...associateJobs[selectedTrade],
        [selectedServiceType]: selectedJobs,
      },
    };

    setQuestionData((prev) => ({
      ...prev,
      associateJobs: updatedAssociateJobs,
    }));

    startTransition(() => {
      let updatedServiceType = selectedServiceType.charAt(0).toUpperCase() + selectedServiceType.slice(1).toLowerCase();
      let previousJobs = questionData?.associateJobs[questionData?.selectedTrade][questionData?.selectedServiceType] || [];
      let missingJob = previousJobs?.length < selectedJobs.length ? selectedJobs[selectedJobs.length - 1] : previousJobs.filter(job => !selectedJobs.includes(job))[0];

      let updatedCombineJobs = combineJobs.map((existingJob) => {
        if (existingJob.job === missingJob && existingJob.trade.tradeName === selectedTrade) {
          if (existingJob.serviceType.includes(updatedServiceType)) {
            return {
              ...existingJob,
              serviceType: existingJob.serviceType.filter((type) => type !== updatedServiceType),
            };
          } else {
            return {
              ...existingJob,
              serviceType: [...existingJob.serviceType, updatedServiceType]
            };
          }
        } else {
          return existingJob;
        }
      });

      selectedJobs?.forEach((selectedJob) => {
        let jobIndex = combineJobs.findIndex((job) => job.job.toLowerCase() === selectedJob.toLowerCase() && job.trade.tradeName === selectedTrade);
        if (jobIndex === -1) {
          updatedCombineJobs.push({
            trade: trades.find((trade) => trade.tradeName === selectedTrade),
            serviceType: [updatedServiceType],
            job: selectedJob,
          });
        }
      });

      let updatedCombineJobsFiltered = updatedCombineJobs.filter(job => job.serviceType.length > 0);

      startTransition(() => {
        setQuestionData((prev) => ({
          ...prev,
          combineJobs: updatedCombineJobsFiltered,
        }));
      });
    })
  };

  const renderJobOptions = () => {
    const selectedTrade = questionData.selectedTrade;
    const selectedServiceType = questionData.selectedServiceType;



    if (selectedTrade && selectedServiceType) {
      if (reducedData[selectedTrade] && reducedData[selectedTrade][selectedServiceType]) {
        return reducedData[selectedTrade][selectedServiceType].map(jobItem => ({
          label: jobItem,
          value: jobItem,
          desc: jobItem
        }));
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const AssociatedQuestions = useMemo(() => getAssociatedQuestions(), [organizationData?.questions]);
  const reducedData = useMemo(() => reduceBackendArray(jobs), [jobs]);
  
  return (
    <>
      <Spin spinning={loading} fullscreen />
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '20px' }}>
        <Title level={4} style={{ margin: 0 }}>
          Associated Questions
        </Title>
      </div>
      <div className="py-2">
        <Button onClick={() => {
          setQuestionData({
            question: "",
            options: [],
            selectedTrade: "Plumbing",
            selectedServiceType: "repair",
            associateJobs: { ...InitialQuestionData.associateJobs },
            combineJobs: []
          });
          setAction("Add");
          setIsModalOpen(true);

        }}>Add Question</Button>
      </div>
      <Alert showIcon icon={<InfoCircleFilled/>} type="info" message="Associated questions are the questions attached to the job to gather additional information about the equipment the job is for." />
      <Divider />
      <div style={{ margin: "20px 0" }}>
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} style={{ color: "#1890ff" }} />
          )}
          style={{
            background: "#f7f7f7",
            border: "1px solid #ddd",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
          items={AssociatedQuestions}
        />
      </div>
      <Modal
        width="50%"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        title={<Title level={3} style={{ fontSize: "20px", textAlign: "center", marginBottom: "20px" }}>{action} Question and Answers</Title>}
        style={{ borderRadius: "8px", overflow: "hidden" }}
      >
        <div style={{ padding: "20px" }}>
          <Title level={4} style={{ fontSize: "18px", marginBottom: "20px" }}>
            Associated Jobs
          </Title>
          <div style={{ marginBottom: "20px" }}>
            <Select
              placeholder="Select trade"
              value={questionData?.selectedTrade}
              onChange={(value) => setQuestionData((prev) => ({ ...prev, selectedTrade: value }))}
              style={{ width: "100%", marginBottom: "20px" }}
            >
              {trades.map((trade) => {
                return (
                  <Option key={trade?.tradeName} value={trade?.tradeName}>
                    {trade?.tradeName}
                  </Option>
                )
              })}
            </Select>
            <Radio.Group
              onChange={(e) => setQuestionData((prev) => ({ ...prev, selectedServiceType: e.target.value }))}
              value={questionData?.selectedServiceType.toLowerCase()}
              style={{ marginBottom: "20px" }}
            >
              {
                services.map((service) => (
                  <Radio.Button key={service?.id} value={service?.name.toLowerCase()}>{service?.name}</Radio.Button>

                ))
              }
            </Radio.Group>

            <Select
              mode="multiple"
              placeholder="Select associated jobs"
              style={{ width: "100%", marginBottom: "20px" }}
              options={renderJobOptions()}
              value={questionData &&
                questionData?.associateJobs &&
                questionData?.selectedTrade &&
                questionData?.associateJobs[questionData.selectedTrade] &&
                questionData?.selectedServiceType &&
                questionData?.associateJobs[questionData.selectedTrade][questionData.selectedServiceType]
                ? questionData.associateJobs[questionData.selectedTrade][questionData.selectedServiceType]
                : []}
              onChange={jobSelection}
            >
            </Select>
          </div>
          <Title level={4} style={{ fontSize: "18px", marginBottom: "20px" }}>
            Question
          </Title>
          <Input
            placeholder="Enter your question"
            value={questionData?.question}
            showCount
            maxLength={100}
            onChange={(e) =>
              setQuestionData((prev) => ({
                ...prev,
                question: e.target.value,
              }))
            }
            style={{ marginBottom: "20px", fontSize: "14px", padding: "8px", borderRadius: "8px" }}
          />

          <Title level={4} style={{ fontSize: "18px", marginBottom: "20px" }}>
            Answers
          </Title>
          <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            {questionData?.options.map((answer, index) => (
              <div key={index} style={{ display: "flex", alignItems: "center" }}>
                <Input
                  placeholder={`Answer ${index + 1}`}
                  showCount
                  maxLength={50}
                  value={answer.option}
                  onChange={(e) => optionHandler(e, index)}
                  style={{ fontSize: "14px", flex: 1, padding: "8px", borderRadius: "8px" }}
                />
                <Button type="link" danger onClick={() => removeAnswer(index)} style={{ marginLeft: "8px" }}>
                  Remove
                </Button>
              </div>
            ))}
          </div>

          <Button
            type="dashed"
            style={{ width: "100%", borderRadius: "8px", marginTop: "20px" }}
            onClick={addAnswer}
            disabled={questionData.options.length >= MAX_ALLOWED_QUESTIONS}
          >
            + Add Answer
          </Button>
        </div>
      </Modal>
    </>
  );

}
