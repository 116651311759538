import { useEffect, useState, useRef, useMemo } from 'react';
import { Card, Typography, Tabs, message, Select, Spin, Table, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllOrganzations } from '../../redux/adminSlice';
import dayjs from 'dayjs';
import axiosInstance from '../../redux/apiAxios';
import { BarChartOutlined, SearchOutlined } from '@ant-design/icons';
import './Adminanalytics.css';

import AnalyticsHeader from './components/AnalyticsHeader';
import FilterControls from './components/FilterControls';
import AnalyticsTable from './components/AnalyticsTable';
import CombinedAnalysisChart from './components/CombinedAnalysisChart';

const { Title } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

export default function Adminanalytics() {
  const { organizationsList, loading } = useSelector((state) => state.admin);
  const { organizationData } = useSelector((state) => state.organization);
  const { trades, services } = useSelector((state) => state.trade);
  const { jobs } = useSelector((state) => state.jobs);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [selectedTradeOrganizations, setSelectedTradeOrganizations] = useState([]);
  const [selectedRange, setSelectedRange] = useState([
    {
      startDate: dayjs().startOf('month').toDate(),
      endDate: dayjs().endOf('month').toDate(),
      key: 'selection',
    },
  ]);
  const [pickerOpen, setPickerOpen] = useState(false);
  const pickerRef = useRef(null);
  const [mergedData, setMergedData] = useState([]);
  const [dateLoading, setDateLoading] = useState(false);
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedMedium, setSelectedMedium] = useState(null);
  const [sourceData, setSourceData] = useState([]);
  const [sourceLoading, setSourceLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('table');
  const [selectedTradeAnalysis, setSelectedTradeAnalysis] = useState(null);
  const [selectedServiceAnalysis, setSelectedServiceAnalysis] = useState(null);
  const [selectedJobAnalysis, setSelectedJobAnalysis] = useState(null);
  const [tradeAnalysisData, setTradeAnalysisData] = useState([]);
  const [tradeAnalysisLoading, setTradeAnalysisLoading] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);
  const [activeInnerTab, setActiveInnerTab] = useState('button_based');
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const dispatch = useDispatch();

  const pageOptions = [
    { label: 'Trade Selection', value: 'Trade Selection', step: 1 },
    { label: 'Service Selection', value: 'Service Selection', step: 2 },
    { label: 'Category List', value: 'Category Selection', step: 3 },
    { label: 'Job Selection', value: 'Job Selection', step: 4 },
    { label: 'Diagnostic Fee', value: 'Agree Fee', step: 5 },
    { label: 'Additional Description', value: 'Additional Description',step:6 },
    { label: 'Select Time Slot', value: 'Select Time Slot', step: 7 },
    { label :"Appointment/Review",value:'Appointment/Review',step:8}
  ];

  useEffect(() => {
    dispatch(fetchAllOrganzations()).then(() => {
      if (organizationsList.length > 0) {
        const allOrgIds = organizationsList.map(org => org._id);
        setSelectedOrganizations([]);
        fetchAnalytics(allOrgIds, selectedRange[0]);
      }
    });
  }, [dispatch, organizationsList.length]);

  useEffect(() => {
    console.log(activeTab);
    if (activeTab === 'analysis' && selectedOrganizations.length === 0 && organizationsList.length > 0) {
      const allOrgIds = organizationsList.map(org => org._id);
      setSelectedOrganizations(allOrgIds);
      fetchSourceData(allOrgIds, selectedRange[0], selectedSource);
    }
    else if (activeTab === 'trade_analysis') {
      if(activeInnerTab === 'button_based'){
        handleTradeAnalysisChange(selectedTradeOrganizations, selectedTradeAnalysis, selectedServiceAnalysis, selectedJobAnalysis, selectedRange[0]);
      }
      else{
        console.log(selectedTradeOrganizations);
        console.log(selectedPage);
        console.log(activeInnerTab)
        handlePageAnalysisChange(selectedTradeOrganizations, selectedPage, selectedRange[0]);
      }
    }
  }, [activeTab, selectedTradeOrganizations.length, organizationsList]);

  const fetchSourceData = async (organizationIds, dateRange, source = null) => {
    try {
      setSourceLoading(true);
      const start_date = dayjs(dateRange.startDate).format("YYYY-MM-DD");
      const end_date = dayjs(dateRange.endDate).format("YYYY-MM-DD");
      
      let queryParams = `startDate=${start_date}&endDate=${end_date}`;
    
      if (organizationIds && organizationIds.length > 0) {
        queryParams += `&organizationIds=${organizationIds.join(',')}`;
      } else if (activeTab === 'analysis') {
        message.info('Please select at least one organization to view analysis data');
        setSourceData([]);
        setSourceLoading(false);
        return;
      }
      
      if (source) queryParams += `&source=${source}`;
      
      const response = await axiosInstance.get(`/scheduler_leads_by_source?${queryParams}`);
      
      if (response.data) {
        setSourceData(response.data);
      }
    } catch(error) {
      console.log("error fetching source data", error);
      message.error('Failed to fetch source data');
    } finally {
      setSourceLoading(false);
    }
  };

  const fetchAnalytics = async (organizationIds, dateRange, source = selectedSource, medium = selectedMedium) => {
    try {
      setDateLoading(true);
      const start_date = dayjs(dateRange.startDate).format("YYYY-MM-DD");
      const end_date = dayjs(dateRange.endDate).format("YYYY-MM-DD");
      const organizationInfo = organizationData.apiKeys;
      
      const orgIdsForTable = activeTab === 'table' ? 
        organizationsList.map(org => org._id) : 
        organizationIds;
      
      let queryParams = `organizationIds=${orgIdsForTable}&startDate=${start_date}&endDate=${end_date}&profile_id=${organizationInfo?.whatConverts_profileId}&organizationId=${orgIdsForTable.join(',')}`;
      if (source) queryParams += `&source=${source}`;
      if (medium) queryParams += `&medium=${medium}`;
      
      const response = await axiosInstance.get(`/scheduler_type_multiple?${queryParams}`);
      
      if (response.data) {
        const formattedData = response.data
        setMergedData(formattedData);
      }
      

      if (activeTab === 'analysis') {
        await fetchSourceData(organizationIds, dateRange, source);
      }
    } catch(error) {
      console.log("error", error);
      message.error('Failed to fetch analytics data');
    } finally {
      setDateLoading(false);
    }
  };

  const handleDateChange = async (ranges) => {
    setSelectedRange([ranges.selection]);
    setDateLoading(true);
    if (activeTab === 'trade_analysis') {
      if (activeInnerTab === 'page_based') {
          await handlePageAnalysisChange(selectedTradeOrganizations, selectedPage,ranges.selection);
      } else {
        await handleTradeAnalysisChange(
          selectedTradeOrganizations,
          selectedTradeAnalysis,
          selectedServiceAnalysis,
          selectedJobAnalysis,
          ranges.selection
        );
      }
    } else {
      await fetchAnalytics(selectedOrganizations, ranges.selection);
    }
  };

  const togglePicker = () => {
    setPickerOpen(!pickerOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isClickInsidePicker = pickerRef.current && pickerRef.current.contains(event.target);
      if (!isClickInsidePicker) {
        setPickerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSourceChange = (value) => {
    setSelectedSource(value);
    fetchAnalytics(selectedOrganizations, selectedRange[0], value, selectedMedium);
  };
  
  const handleMediumChange = (value) => {
    setSelectedMedium(value);
    fetchAnalytics(selectedOrganizations, selectedRange[0], selectedSource, value);
  };

  const handleClearFilters = () => {
    setSelectedSource(null);
    setSelectedMedium(null);
    fetchAnalytics(selectedOrganizations, selectedRange[0], null, null);
  };

  const handleOrganizationChange = (selectedOrgIds) => {
    setSelectedOrganizations(selectedOrgIds);
  
    if (activeTab === 'analysis') {
      if (selectedOrgIds.length === 0) {
        setSourceData([]);
      } else {
        fetchAnalytics(selectedOrgIds, selectedRange[0], selectedSource, selectedMedium);
      }
    }
  };

  const handleTradeOrganizationChange = (value) => {
    setSelectedTradeOrganizations(value);
    handleTradeAnalysisChange(
      value,
      selectedTradeAnalysis,
      selectedServiceAnalysis,
      selectedJobAnalysis,
      selectedRange[0]
    );
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
    
    if (key === 'analysis' && selectedOrganizations.length === 0) {
      const allOrgIds = organizationsList.map(org => org._id);
      setSelectedOrganizations(allOrgIds);
      fetchSourceData(allOrgIds, selectedRange[0], selectedSource);
    }
  };

  const handleTradeAnalysisChange = async (organizationNames = selectedTradeOrganizations, trade, service, job, dateRange = selectedRange[0]) => {
    try {
      setTradeAnalysisLoading(true);
      const tradeName = trades.find(t => t._id === trade)?.tradeName || '';
      const serviceName = services.find(s => s.id === service)?.name || '';

      const start_date = dayjs(dateRange.startDate).format("YYYY-MM-DD");
      const end_date = dayjs(dateRange.endDate).format("YYYY-MM-DD");
      const response = await axiosInstance.get('/schduler_job_analytics', {
        params: {
          trade: tradeName,
          service: serviceName,
          job: job,
          startDate: start_date,
          endDate: end_date,
          organizationIds: organizationNames
        }
      });
      
      setTradeAnalysisData(response.data);
    } catch (error) {
      console.error('Error fetching trade analysis:', error);
      message.error('Failed to fetch trade analysis data');
    } finally {
      setTradeAnalysisLoading(false);
    }
  };

  const handlePageAnalysisChange = async (organizationNames = selectedTradeOrganizations, page,dateRange = selectedRange[0]) => {
    try {
      setTradeAnalysisLoading(true);
      const start_date = dayjs(dateRange.startDate).format("YYYY-MM-DD");
      const end_date = dayjs(dateRange.endDate).format("YYYY-MM-DD");

      const response = await axiosInstance.get('/schduler_page_analytics', {
        params: {
          page: page,
          startDate: start_date,
          endDate: end_date,
          organizationIds: organizationNames
        }
      });
      
      setTradeAnalysisData(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching page analysis:', error);
      message.error('Failed to fetch page analysis data');
    } finally {
      setTradeAnalysisLoading(false);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button
            onClick={() => confirm()}
            style={{ width: 90 }}
          >
            Search
          </button>
          <button onClick={() => clearFilters()} style={{ width: 90 }}>
            Reset
          </button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const sortedOrganizationsList = useMemo(() => {
    if (!organizationsList || organizationsList.length === 0) return [];
    return [...organizationsList].sort((a, b) => 
      a.organization_name.charAt(0).localeCompare(b.organization_name.charAt(0))
    );
  }, [organizationsList]);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <BarChartOutlined style={{ fontSize: '24px', marginRight: '10px' }} />
        <Title level={2} style={{ marginBottom: '20px', fontSize: '24px' }}>Analytics</Title>
      </div>
      <br/>
      <br/>
      <Card style={{ padding: '20px'}}>
        <AnalyticsHeader 
          mergedData={mergedData}
          pickerRef={pickerRef}
          selectedRange={selectedRange}
          togglePicker={togglePicker}
          pickerOpen={pickerOpen}
          handleDateChange={handleDateChange}
        />

        <FilterControls 
          selectedSource={selectedSource}
          handleSourceChange={handleSourceChange}
          selectedMedium={selectedMedium}
          handleMediumChange={handleMediumChange}
          activeTab={activeTab}
          handleClearFilters={handleClearFilters}
          organizationsList={organizationsList}
          selectedOrganizations={selectedOrganizations}
          handleOrganizationChange={handleOrganizationChange}
        />

        <Tabs 
          activeKey={activeTab} 
          onChange={handleTabChange}
          type="card"
          style={{ marginBottom: '20px' }}
        >
          <TabPane tab="Organization Table" key="table">
            <AnalyticsTable 
              mergedData={mergedData}
              loading={loading || dateLoading}
            />
          </TabPane>
          <TabPane tab="Source & Booking Rate Analysis" key="analysis">
            {selectedOrganizations.length === 0 ? (
              <div style={{ textAlign: 'center', padding: '40px 0' }}>
                <p>Please select at least one organization to view analysis data</p>
              </div>
            ) : (
              <CombinedAnalysisChart 
                sourceData={sourceData}
                sourceLoading={sourceLoading}
              />
            )}
          </TabPane>
          <TabPane tab="Job Abandonment Analysis" key="trade_analysis">
            <Tabs defaultActiveKey="button_based" 
              onChange={(key) => {
                setActiveInnerTab(key);
                if (key === 'page_based') {
                  const orgNames = selectedTradeOrganizations.length > 0 
                    ? selectedTradeOrganizations 
                    : organizationsList.map(org => org.organization_name);
                  handlePageAnalysisChange(orgNames, null);
                } else {
                  setSelectedPage(null);
                  handleTradeAnalysisChange(organizationsList.map(org => org.organization_name), null, null, null, selectedRange[0]);
                }
              }}
            >
              <TabPane tab="Button Based Analysis" key="button_based">
                <div style={{ marginBottom: '20px' }}>
                  <div style={{ display: 'flex', gap: '16px', marginBottom: '20px' }}>
                    <Select
                      placeholder="Select Organizations"
                      style={{ width: 300 }}
                      mode="multiple"
                      allowClear
                      value={selectedTradeOrganizations}
                      onChange={(value) => {
                        setSelectedTradeOrganizations(value);
                        if (activeInnerTab === 'button_based') {
                          handleTradeAnalysisChange(
                            value,
                            selectedTradeAnalysis,
                            selectedServiceAnalysis,
                            selectedJobAnalysis,
                            selectedRange[0]
                          );
                        } else {
                          handlePageAnalysisChange(value, selectedPage, selectedRange[0]);
                        }
                      }}
                    >
                      {sortedOrganizationsList.map((org) => (
                        <Option key={org._id} value={org.organization_name}>
                          {org.organization_name}
                        </Option>
                      ))}
                    </Select>

                    <Select
                      placeholder="Select Trade"
                      style={{ width: 200 }}
                      value={selectedTradeAnalysis}
                      allowClear
                      onChange={(value) => {
                        setSelectedTradeAnalysis(value);
                        setSelectedServiceAnalysis(null);
                        setSelectedJobAnalysis(null);
                        handleTradeAnalysisChange(selectedTradeOrganizations, value, null, null);
                      }}
                    >
                      {trades.map((trade) => (
                        <Option key={trade._id} value={trade._id}>
                          {trade.tradeName}
                        </Option>
                      ))}
                    </Select>

                    <Select
                      placeholder="Select Service"
                      style={{ width: 200 }}
                      value={selectedServiceAnalysis}
                      disabled={!selectedTradeAnalysis}
                      allowClear
                      onChange={(value) => {
                        setSelectedServiceAnalysis(value);
                        setSelectedJobAnalysis(null);
                        handleTradeAnalysisChange(selectedTradeOrganizations, selectedTradeAnalysis?selectedTradeAnalysis:null, value, null);
                      }}
                    >
                      {services.map((service) => (
                        <Option key={service.id} value={service.id}>
                          {service.name}
                        </Option>
                      ))}
                    </Select>

                    <Select
                      placeholder="Select Job"
                      style={{ width: 400 }}
                      value={selectedJobAnalysis}
                      allowClear
                      disabled={!selectedServiceAnalysis}
                      onChange={(value) => {
                        setSelectedJobAnalysis(value);
                        handleTradeAnalysisChange(selectedTradeOrganizations, selectedTradeAnalysis, selectedServiceAnalysis, value);
                      }}
                    >
                      {jobs
                        .filter(
                          (job) =>
                            job.trade._id === selectedTradeAnalysis &&
                            job.serviceType === services.find((s) => s.id === selectedServiceAnalysis)?.name.toLowerCase()
                        )
                        .map((job) => (
                          <Option key={job._id} value={job.jobItem}>
                            {job.jobItem}
                          </Option>
                        ))}
                    </Select>
                  </div>
                  {tradeAnalysisLoading ? (
                    <div style={{ textAlign: 'center', padding: '40px 0' }}>
                      <Spin />
                    </div>
                  ) : tradeAnalysisData.length === 0 ? (
                    <div style={{ textAlign: 'center', padding: '40px 0' }}>
                      <p>Select filters to view job abandonment data</p>
                    </div>
                  ) : (
                    <div>
                      <Table
                        dataSource={tradeAnalysisData}
                        onChange={handleTableChange}
                        columns={[
                          {
                            title: 'Organization',
                            dataIndex: 'organization_name',
                            key: 'organization_name',
                            ...getColumnSearchProps('organization_name'),
                            filters: [...new Set(tradeAnalysisData.map(item => item.organization_name))]
                              .map(org => ({ text: org, value: org })),
                            filteredValue: filteredInfo.organization_name || null,
                            onFilter: (value, record) => record.organization_name.includes(value),
                            sorter: (a, b) => a.organization_name.localeCompare(b.organization_name),
                            sortOrder: sortedInfo.columnKey === 'organization_name' ? sortedInfo.order : null,
                          },
                          {
                            title: 'Button Name',
                            dataIndex: 'button_name',
                            key: 'button_name',
                            ...getColumnSearchProps('button_name'),
                            filters: [...new Set(tradeAnalysisData.map(item => item.button_name))]
                              .map(name => ({ text: name, value: name })),
                            filteredValue: filteredInfo.button_name || null,
                            onFilter: (value, record) => record.button_name.includes(value),
                            sorter: (a, b) => a.button_name.localeCompare(b.button_name),
                            sortOrder: sortedInfo.columnKey === 'button_name' ? sortedInfo.order : null,
                          },
                          {
                            title: 'Button Value',
                            dataIndex: 'button_value',
                            key: 'button_value',
                            ...getColumnSearchProps('button_value'),
                            filters: [...new Set(tradeAnalysisData.map(item => item.button_value))]
                              .map(value => ({ text: value, value: value })),
                            filteredValue: filteredInfo.button_value || null,
                            onFilter: (value, record) => record.button_value.includes(value),
                            sorter: (a, b) => a.button_value.localeCompare(b.button_value),
                            sortOrder: sortedInfo.columnKey === 'button_value' ? sortedInfo.order : null,
                          },
                          {
                            title: 'Total Abandonments',
                            dataIndex: 'total_abandonments',
                            key: 'total_abandonments',
                            sorter: (a, b) => a.total_abandonments - b.total_abandonments,
                            sortOrder: sortedInfo.columnKey === 'total_abandonments' ? sortedInfo.order : null,
                          }
                        ]}
                        pagination={false}
                      />
                    </div>
                  )}
                </div>
              </TabPane>
              <TabPane tab="Page Based Analysis" key="page_based">
                <div style={{ marginBottom: '20px' }}>
                  <div style={{ display: 'flex', gap: '16px', marginBottom: '20px' }}>
                    <Select
                      placeholder="Select Organizations"
                      style={{ width: 300 }}
                      mode="multiple"
                      allowClear
                      value={selectedTradeOrganizations}
                      onChange={(value) => {
                        setSelectedTradeOrganizations(value);
                        handlePageAnalysisChange(value, selectedPage, selectedRange[0]);
                      }}
                    >
                      {sortedOrganizationsList.map((org) => (
                        <Option key={org._id} value={org.organization_name}>
                          {org.organization_name}
                        </Option>
                      ))}
                    </Select>

                    <Select
                      placeholder="Select Page"
                      style={{ width: 400 }}
                      value={selectedPage}
                      allowClear
                      onChange={(value) => {
                        setSelectedPage(value);
                        handlePageAnalysisChange(selectedTradeOrganizations, value,selectedRange[0]);
                      }}
                    >
                      {pageOptions.map((page) => (
                        <Option key={page.value} value={page.value}>
                          {page.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  {tradeAnalysisLoading ? (
                    <div style={{ textAlign: 'center', padding: '40px 0' }}>
                      <Spin />
                    </div>
                  ) : tradeAnalysisData.length === 0 ? (
                    <div style={{ textAlign: 'center', padding: '40px 0' }}>
                      <p>Select filters to view page abandonment data</p>
                    </div>
                  ) : (
                    <div>
                      <Table
                        dataSource={tradeAnalysisData}
                        onChange={handleTableChange}
                        columns={[
                          {
                            title: 'Organization',
                            dataIndex: 'organization_name',
                            key: 'organization_name',
                            ...getColumnSearchProps('organization_name'),
                            filters: [...new Set(tradeAnalysisData.map(item => item.organization_name))]
                          },
                          {
                            title: 'Page',
                            dataIndex: 'button_value',
                            key: 'page',
                            render: (buttonValue) => {
                              const pageOption = pageOptions.find(p => p.value === buttonValue);
                              return pageOption?.label || buttonValue;
                            },
                            ...getColumnSearchProps('button_value'),
                          },
                          {
                            title: 'Total Abandonments',
                            dataIndex: 'total_abandonments',
                            key: 'total_abandonments',
                            sorter: (a, b) => a.total_abandonments - b.total_abandonments,
                          }
                        ]}
                        pagination={false}
                      />
                    </div>
                  )}
                </div>
              </TabPane>
            </Tabs>
          </TabPane>
        </Tabs>
      </Card>
    </>
  );
}
