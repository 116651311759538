import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Col,
  Collapse,
  Divider,
  Flex,
  InputNumber,
  List,
  Row,
  Select,
  Spin,
  Switch,
  Tag,
  Typography,
  theme,
} from "antd";

import { CaretRightOutlined } from "@ant-design/icons";

import {
  updateJobMappings,
  updateOrganization,
} from "../../redux/organizationSlice";
import DispatchText from "./dispatchText";
import JobMapping from "./jobMapping";
import { useCallback } from "react";
import SaveButton from "../UI/savebutton";

const { Title, Text } = Typography;

const CreateJobMapping = () => {
  const [offeredJobs, setOfferedJobs] = useState([]);
  const [isStateUpdate, setIsStateUpdate] = useState(false);
  const [selectedTrade, setSelectedTrade] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [jobItemsData, setJobItemsData] = useState([]);
  const [isDirty, setIsDirty] = useState(false);

  const { token } = theme.useToken();

  const {
    organizationData,
    serviceTitanBusinessUnits,
    serviceTitanJobs,
    loading,
  } = useSelector((state) => state?.organization);
  const { trades = [], services = [] } = useSelector((state) => state?.trade);
  const { jobs = [] } = useSelector((state) => state?.jobs);

  const dispatch = useDispatch();

  const onChange = (value, jobItemId) => {
    setIsDirty(true)
    const jobExists = jobItemsData.findIndex((e) => e?.jobItem === jobItemId);
    if (jobItemsData?.length && value && jobExists !== -1) {
      setJobItemsData(
        jobItemsData.map((item) => {
          if (item?.jobItem === jobItemId) {
            return {
              ...item,
              ...value,
            };
          } else {
            return item;
          }
        })
      );
    } else {
      const data = {
        jobItem: jobItemId,
        ...value,
      };
      setJobItemsData((jobItemsData) => [...jobItemsData, data]);

    }
  };

  const onSave = () => {
    dispatch(
      updateJobMappings({ selectedTrade, services, serviceType, jobItemsData })
    );
    setIsStateUpdate(true);
    setIsDirty(false)
  };

  useEffect(() => {
    if (selectedTrade && serviceType && organizationData) {
      let data =
        organizationData?.jobs_mapping?.find(
          (e) => e.trade === selectedTrade
        )?.[serviceType] || [];
      if(data && data?.length === 0){
        data= organizationData?.jobs_mapping?.find(
          (e) => e.trade === selectedTrade
        )?.additionalServices?.[serviceType]
      }
      if(data){
        setJobItemsData([...data]);
      }
      const jobsData = jobs.filter(
        (item) =>
          item?.trade?._id === selectedTrade &&
          item?.serviceType?.toLowerCase() === serviceType?.toLowerCase()
      );
      setOfferedJobs(jobsData);
    }
    if (isStateUpdate) {
      dispatch(
        updateOrganization({
          id: organizationData._id,
          payload: {
            jobs_mapping: organizationData?.jobs_mapping,
          },
          page: "Job Mapping"
        })
      );
      setIsStateUpdate(false);
    }
  }, [
    dispatch,
    organizationData,
    selectedTrade,
    serviceType,
    isStateUpdate,
    jobs,
  ]);

  // Filter `option.label` match the user type `input`
  const filterOption = useCallback(
    (input, option) =>
      (option?.label ?? "").toLowerCase().includes(input.toLowerCase()),
    []
  );

  const getAssociatedQuestions = useCallback(
    (item) => {
      const Questions = [];
      organizationData?.questions?.forEach((question) => {
        const jobMatched = question?.associateJobs.findIndex(
          (e) =>
            e?.trade?.tradeName.toLowerCase() ===
              item?.trade?.tradeName?.toLowerCase() &&
            e?.serviceType.findIndex(
              (ser) => ser.toLowerCase() === item?.serviceType?.toLowerCase()
            ) !== -1 &&
            e?.job?.toLowerCase() === item?.jobItem?.toLowerCase()
        );
        if (jobMatched !== -1) {
          const questionObj = {
            key: question?.question,
            label: question?.question,
            children: (
              <div style={{ paddingLeft: 24 }}>
                {question?.options?.map((option) => (
                  <Tag color="geekblue" key={option}>
                    {option?.option}
                  </Tag>
                ))}
              </div>
            ),
            style: {
              marginBottom: 10,
              background: token.colorFillAlter,
              borderRadius: token.borderRadiusLG,
              border: "none",
            },
          };
          Questions.push(questionObj);
        }
      });
      return Questions;
    },
    [organizationData?.questions]
  );

  return (
    <>
      <Spin spinning={loading} fullscreen />
      <Title level={3} style={{ marginBottom: "50px" }}>
        Job Mapping
      </Title>
      <Flex vertical gap={20} justify="center">
        <Text type="secondary" strong underline>
          Autobot.ai Trades Offering
        </Text>
        <Flex justify="space-between">
          <Flex vertical gap={10}>
            <Flex gap={20}>
              <Select
                showSearch
                placeholder="Select a Trade"
                optionFilterProp="children"
                onChange={(e) => setSelectedTrade(e)}
                filterOption={filterOption}
                options={trades.map((item) => ({
                  label: item.tradeName,
                  value: item._id,
                }))}
                size="middle"
                style={{ width: 250 }}
              />

              <Select
                showSearch
                placeholder="Select Service Type"
                optionFilterProp="children"
                onChange={(e) => {
                  setServiceType(e);
                }}
                filterOption={filterOption}
                options={services.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                size="middle"
                style={{ width: 200 }}
              />
            </Flex>
            <Text type="danger" strong underline italic>
              * Save your changes before switching trades and service type
            </Text>
          </Flex>
          {/* <Flex vertical align="flex-end">
            <Button
              type="primary"
              style={{ width: "max-content" }}
              onClick={onSave}
              disabled={!selectedTrade || !serviceType}
            >
              Save Mapping
            </Button>
          </Flex> */}
        </Flex>
      </Flex>
      <Divider orientation="left" style={{margin:4}}>Selected trade job items</Divider>
      {/* Service Titan Section */}
        {selectedTrade.length && serviceType.length ? (
          offeredJobs?.map((item) => {
            const jobId = item?._id;
            const mappedData = jobItemsData?.find((e) => e.jobItem === jobId);
            const AssociatedQuestions = getAssociatedQuestions(item);

            return (
              <Row key={jobId}>
                <Col lg={24} xl={{ span: 16, offset: 5 }}>
                  <div
                    style={{
                      width: "100%",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                      border: "1px solid #e0e0e0",
                      borderRadius: "8px",
                      padding: "8px",
                      backgroundColor: "#fff",
                      marginBottom: "20px",
                    }}
                  >
                    <Collapse
                      bordered={false}
                      expandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                      )}
                      style={{ background: token.colorBgContainer }}
                    >
                      <Collapse.Panel
                        key={jobId}
                        header={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              type="secondary"
                              strong
                              style={{ padding: "2px 0" }}
                            >
                              {item.jobItem || "Job Details"}
                            </Text>
                            <Flex
                              onClick={(e) => e.stopPropagation()}
                              align="center"
                              style={{ height: "100%" }}
                            >
                              <Switch
                                size="middle"
                                checked={mappedData?.is_active}
                                style={{ width: 35 }}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) =>
                                  onChange({ is_active: e }, jobId)
                                }
                              />
                            </Flex>
                          </div>
                        }
                        extra={() => (
                          <Flex align="center" style={{ height: "100%" }}>
                            <Switch
                              size="middle"
                              checked={mappedData?.is_active}
                              style={{ width: 35 }}
                              onClick={(e) => e.stopPropagation()}
                              onChange={(e) =>
                                onChange({ is_active: e }, jobId)
                              }
                            />
                          </Flex>
                        )}
                      >
                        <Flex
                          align="flex-start"
                          gap={10}
                          style={{ width: "100%" }}
                          vertical
                        >
                          <JobMapping
                            key={jobId}
                            jobId={jobId}
                            mappedData={mappedData}
                            serviceTitanBusinessUnits={
                              serviceTitanBusinessUnits
                            }
                            serviceTitanJobs={serviceTitanJobs}
                            filterOption={filterOption}
                            onChange={onChange}
                          />
                          <div
                            style={{
                              width: "100%",
                              border: "1px solid #e0e0e0",
                              borderRadius: "8px",
                              padding: "16px", // Reduced padding
                              backgroundColor: "#fff",
                              marginBottom: "20px",
                            }}
                          >
                            <Flex style={{ width: "100%" }} gap={20}>
                              <Flex vertical gap={10} style={{ width: "100%" }}>
                                <Text
                                  type="secondary"
                                  strong
                                  underline
                                  style={{ padding: "4px 0" }} // Reduced padding
                                >
                                  Member Dispatch Fee
                                </Text>
                                <Flex gap={10}>
                                  <InputNumber
                                    size="middle"
                                    addonBefore="$"
                                    defaultValue={0}
                                    min={0}
                                    value={mappedData?.member_dispatch_fee}
                                    onChange={(e) =>
                                      onChange(
                                        { member_dispatch_fee: e },
                                        jobId
                                      )
                                    }
                                    placeholder="Dispatch Fee"
                                    style={{ width: "100%" }}
                                  />
                                </Flex>
                              </Flex>
                            </Flex>

                            <DispatchText
                              id={jobId}
                              member_dispatch_fee={
                                mappedData?.member_dispatch_fee
                              }
                              dispatch_fee={mappedData?.dispatch_fee}
                              isDefault={mappedData?.use_default_dispatch}
                              header={mappedData?.dispatch_header}
                              body={mappedData?.dispatch_body}
                              checkboxText={mappedData?.dispatch_text}
                              onChange={onChange}
                            />
                          </div>
                          <div
                            style={{
                              width: "100%",
                              border: "1px solid #e0e0e0",
                              borderRadius: "8px",
                              padding: "16px", // Reduced padding
                              backgroundColor: "#fff",
                              marginBottom: "20px",
                            }}
                          >
                            {AssociatedQuestions?.length > 0 ? (
                              <Text
                                type="secondary"
                                strong
                                underline
                                style={{ padding: "4px 0" }} // Reduced padding
                              >
                                Associated Questions
                              </Text>
                            ) : (
                              <Text
                                type="secondary"
                                style={{ padding: "4px 0" }}
                              >
                                No Associated Questions For This Job
                              </Text>
                            )}
                            <Flex vertical>
                              <Collapse
                                bordered={false}
                                expandIcon={({ isActive }) => (
                                  <CaretRightOutlined
                                    rotate={isActive ? 90 : 0}
                                  />
                                )}
                                style={{
                                  background: token.colorBgContainer,
                                }}
                                items={AssociatedQuestions}
                              />
                            </Flex>
                          </div>
                        </Flex>
                      </Collapse.Panel>
                    </Collapse>
                  </div>
                </Col>
              </Row>
            );
          })
        ) : (
          <List dataSource={[]} />
        )}
      <SaveButton onSubmit={onSave} isDirty={isDirty}/>
    </>
  );
};

export default CreateJobMapping;
