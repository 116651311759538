import { Button, Card, Select, Space, Spin, Input, Row, Col } from 'antd';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { updateOrganization } from '../../../../redux/organizationSlice';
import { ArrowRightOutlined } from '@ant-design/icons';

import './renamingtrades.css';

const { Option } = Select;

export default function TradesOperations() {
    const { organizationData, loading } = useSelector((state) => state.organization);
    const { trades } = useSelector((state) => state.trade);

    const dispatch = useDispatch();

    const [selectedTrade, setSelectedTrade] = useState(null); // Changed from array to single value
    const [newTradeName, setNewTradeName] = useState('');
    const [originalTradeName, setOriginalTradeName] = useState('');

    const handleTradeChange = (value) => {
        const trade = trades.find(trade => trade._id === value);
        setSelectedTrade(value);
        setOriginalTradeName(trade.tradeName);
    };

    const renameTrade = () => {
        if (!selectedTrade || !newTradeName.trim()) {
            alert("Please select a trade and enter a new trade name.");
            return;
        }

        const updatedOrganizationData = structuredClone(organizationData);
        const existingTrade = updatedOrganizationData?.organization_trades?.find(trade => trade.tradeId === selectedTrade);

        if (existingTrade) {
            existingTrade.tradeName = newTradeName.charAt(0).toUpperCase() + newTradeName.slice(1);
        } else {
            updatedOrganizationData.organization_trades.push({
                tradeId: selectedTrade,
                originalName: originalTradeName,
                tradeName: newTradeName.charAt(0).toUpperCase() + newTradeName.slice(1)
            });
        }
        // dispatch(updateOrganization({ id: organizationData._id, payload: updatedOrganizationData }));
    };

    return (
        <>
            <Spin spinning={loading} />
            <div style={{ padding: '24px' }}>
                <Card
                    title="Trade Renaming For Scheduler"
                    bordered={false}
                    className="custom-card"
                    headStyle={{ textAlign: 'center', color: '#555', fontWeight: 'bold' }}
                >
                    <Space direction="vertical" size="middle" style={{ width: '100%' }}>

                        <Select
                            placeholder="Select Trade"
                            onChange={handleTradeChange}
                            style={{ width: '100%' }}
                            value={selectedTrade}
                        >
                            {trades.map((trade) => (
                                <Option key={trade._id} value={trade._id}>{trade.tradeName}</Option>
                            ))}
                        </Select>

                        <Row align="middle" className="custom-row">
                            <Col span={10}>
                                <Input
                                    placeholder="Original Trade Name"
                                    value={originalTradeName}
                                    disabled
                                    style={{ width: '100%' }}
                                />
                            </Col>
                            <Col span={4} className="arrow-col">
                                <ArrowRightOutlined />
                            </Col>
                            <Col span={10}>
                                <Input
                                    placeholder="Enter New Trade Name"
                                    value={newTradeName}
                                    onChange={(e) => setNewTradeName(e.target.value)}
                                    style={{ width: '100%' }}
                                />
                            </Col>
                        </Row>

                        <Button
                            onClick={renameTrade}
                            className="custom-button"
                            disabled={!selectedTrade || !newTradeName.trim()}
                        >
                            Rename Trade
                        </Button>
                    </Space>
                </Card>
            </div>
        </>
    );
}
