import React, { Profiler } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import { persistedReducer } from "./redux/index";
import { PersistGate } from "redux-persist/integration/react";
import {
	persistStore,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import NotFound from "./pages/NotFound";

const root = ReactDOM.createRoot(document.getElementById("root"));

const store = configureStore({
	reducer: persistedReducer,
	// devTools: process.env.NODE_ENV !== "production",
	middleware: getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
		},
	}),
});

let persistor = persistStore(store);

root.render(
	<Profiler id="profiler_admin">
		<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<ConfigProvider
						theme={{
							token: {
								// Seed Token
								colorPrimary: "#337ab7",
								// borderRadius: 2,

								// Alias Token
								// colorBgContainer: "#f6ffed",
							},
						}}
					>
						<ErrorBoundary fallback={<NotFound />}>
							<App />
						</ErrorBoundary>
					</ConfigProvider>
				</PersistGate>
		</Provider>
	</Profiler>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
