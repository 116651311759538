import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Flex, Typography } from "antd";
import { CloudServerOutlined } from "@ant-design/icons";
import { styles } from "./FileUploadingConstants";
import {
	useCSVReader,
	lightenDarkenColor,
	formatFileSize,
} from "react-papaparse";
import { updateOrganization } from "../../../redux/organizationSlice";

const { Title } = Typography;
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
	DEFAULT_REMOVE_HOVER_COLOR,
	40
);
const Default_Services=['repair','maintenance','estimate'];
const JobsMappingUpload = () => {
	const dispatch = useDispatch();
	const {
		organizationData,
		serviceTitanBusinessUnits,
		serviceTitanJobs,
		loading,
	} = useSelector((state) => state?.organization);
	const { trades } = useSelector((state) => state?.trade);
	const { jobs } = useSelector((state) => state?.jobs);

	const { CSVReader } = useCSVReader();
	const [zoneHover, setZoneHover] = useState(false);
	const [uploadedMappings, setUploadedMappings] = useState(null);
	const [removeHoverColor, setRemoveHoverColor] = useState(
		DEFAULT_REMOVE_HOVER_COLOR
	);

	const handleMappingsUpload = (results) => {
		let mappingsStructure = [];
		const {
			default_dispatch_header,
			default_dispatch_body,
			default_dispatch_text,
		} = organizationData;
		const uniqueTradesInCsv = new Set();
		results?.data?.forEach((row, index) => {
			if (index > 0) {
				const trade = trades?.find((e) => e?.tradeName === row[0]);
				if (trade) {
					uniqueTradesInCsv.add(trade._id);
				}
			}
		});
		mappingsStructure = Array.from(uniqueTradesInCsv).map(tradeId => ({
			trade: tradeId,
			repair: [],
			maintenance: [],
			estimate: [],
			additionalServices: {}
		}));

		results?.data?.forEach((row, index) => {
			if (index > 0) {
				const trade = trades?.find((e) => e?.tradeName === row[0]);
				const serviceType = row[2].toLowerCase();
				const job = jobs?.find(
					(e) =>
						e?.jobItem === row[1] &&
						e?.serviceType === serviceType &&
						e?.trade?._id === trade?._id
				);

				if (job && trade && serviceType) {
					const tradeIndex = mappingsStructure.findIndex(
						(e) => e.trade === trade?._id
					);

					if (tradeIndex !== -1) {
						const newJobMapping = {
							jobItem: job?._id,
							jobItemName: job?.jobItem,
							serviceType,
							is_active: row[3]?.toLowerCase() === "yes" ? true : false,
							st_business_unit_id: serviceTitanBusinessUnits?.find(
								(e) => e?.name === row[4]
							)?.id || "",
							st_business_unit_name: serviceTitanBusinessUnits?.find(
								(e) => e?.name === row[4]
							)?.name || "",
							st_non_member_job_id: serviceTitanJobs?.find(
								(e) => e?.name === row[5]
							)?.id || "",
							st_non_member_job_name: serviceTitanJobs?.find(
								(e) => e?.name === row[5]
							)?.name || "",
							is_member: true,
							st_member_job_id:
								serviceTitanJobs?.find((e) => e?.name === row[6])?.id ||
								"",
							st_member_job_name:
								serviceTitanJobs?.find((e) => e?.name === row[6])?.name ||
								"",
							dispatch_fee: row[7]?.replace("$", "") || 0,
							member_dispatch_fee: row[8]?.replace("$", "") || 0,
							use_default_dispatch:
								default_dispatch_header.trim() === row[9].trim() &&
								default_dispatch_body.trim() === row[10].trim() &&
								default_dispatch_text.trim() === row[11].trim()
									? true
									: false,
							dispatch_header: row[9],
							dispatch_body: row[10],
							dispatch_text: row[11],
						};

						if (Default_Services.includes(serviceType)) {
							mappingsStructure[tradeIndex][serviceType].push(newJobMapping);
						} else {
							if (!mappingsStructure[tradeIndex].additionalServices[serviceType]) {
								mappingsStructure[tradeIndex].additionalServices[serviceType] = [];
							}
							mappingsStructure[tradeIndex].additionalServices[serviceType].push(newJobMapping);
						}
					}
				}
			}
		});

		console.log("mapping structure", mappingsStructure);
		setUploadedMappings(mappingsStructure);
	};

	const handleMappings = () => {
		// Convert string values to proper types and ensure data structure matches schema
		const cleanedMappings = uploadedMappings.map(mapping => {
			// Ensure all required fields exist with proper types
			const cleanMapping = {
				trade: mapping.trade,
				repair: [],
				maintenance: [],
				estimate: [],
				additionalServices: mapping.additionalServices || {},
				_id: mapping._id
			};

			// Helper function to clean job entries
			const cleanJobEntry = (job) => ({
				jobItem: job.jobItem || '',
				jobItemName: job.jobItemName || '',
				serviceType: job.serviceType || '',
				is_active: Boolean(job.is_active),
				st_business_unit_id: job.st_business_unit_id || '',
				st_business_unit_name: job.st_business_unit_name || '',
				is_member: Boolean(job.is_member),
				st_member_job_id: job.st_member_job_id || '',
				st_member_job_name: job.st_member_job_name || '',
				st_non_member_job_id: job.st_non_member_job_id || '',
				st_non_member_job_name: job.st_non_member_job_name || '',
				dispatch_fee: Number(job.dispatch_fee) || 0,
				member_dispatch_fee: Number(job.member_dispatch_fee) || 0,
				use_default_dispatch: Boolean(job.use_default_dispatch),
				dispatch_header: job.dispatch_header || '',
				dispatch_body: job.dispatch_body || '',
				dispatch_text: job.dispatch_text || '',
				associated_questions: Array.isArray(job.associated_questions) ? job.associated_questions : []
			});

			// Clean each service type array
			['repair', 'maintenance', 'estimate'].forEach(serviceType => {
				if (Array.isArray(mapping[serviceType])) {
					cleanMapping[serviceType] = mapping[serviceType].map(cleanJobEntry);
				}
			});

			// Clean additional services if they exist
			if (mapping.additionalServices) {
				cleanMapping.additionalServices = Object.keys(mapping.additionalServices).reduce((acc, key) => {
					if (Array.isArray(mapping.additionalServices[key])) {
						acc[key] = mapping.additionalServices[key].map(cleanJobEntry);
					}
					return acc;
				}, {});
			}

			return cleanMapping;
		});

		dispatch(
			updateOrganization({
				id: organizationData._id,
				payload: {
					jobs_mapping: cleanedMappings
				},
				page: "Upload Job Mappings"
			})
		);
	};

	return (
		<>
			<Flex align="center" gap={10} style={{ marginBottom: "50px" }}>
				<CloudServerOutlined style={{ fontSize: 20 }} />
				<Title level={3} style={{ margin: 0 }}>
					Upload Job Mappings
				</Title>
			</Flex>

			<CSVReader
				onUploadAccepted={(results) => {
					setUploadedMappings(null);
					handleMappingsUpload(results);
					setZoneHover(false);
				}}
				onDragOver={(event) => {
					event.preventDefault();
					setZoneHover(true);
				}}
				onDragLeave={(event) => {
					event.preventDefault();
					setZoneHover(false);
				}}
			>
				{({
					getRootProps,
					acceptedFile,
					ProgressBar,
					getRemoveFileProps,
					Remove,
				}) => (
					<>
						<div
							{...getRootProps()}
							style={Object.assign(
								{},
								styles.zone,
								zoneHover && styles.zoneHover
							)}
						>
							{acceptedFile ? (
								<>
									<div style={styles.file}>
										<div style={styles.info}>
											<span style={styles.size}>
												{formatFileSize(acceptedFile.size)}
											</span>
											<span style={styles.name}>{acceptedFile.name}</span>
										</div>
										<div style={styles.progressBar}>
											<ProgressBar />
										</div>
										<div
											{...getRemoveFileProps()}
											style={styles.remove}
											onMouseOver={(event) => {
												event.preventDefault();
												setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
											}}
											onMouseOut={(event) => {
												event.preventDefault();
												setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
											}}
											onClick={(event) => {
												getRemoveFileProps().onClick(event);
												setUploadedMappings(null);
											}}
										>
											<Remove color={removeHoverColor} />
										</div>
									</div>
								</>
							) : (
								"Drop CSV file here or click to upload"
							)}
						</div>
					</>
				)}
			</CSVReader>

			<Button
				onClick={handleMappings}
				type="primary"
				style={{ marginTop: 50 }}
				disabled={uploadedMappings === null}
				loading={loading}
			>
				Save Mappings
			</Button>
		</>
	);
};

export default JobsMappingUpload;
