/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Flex,
  Input,
  Select,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";

import { SettingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "antd/es/input/TextArea";
import SaveButton from "../UI/savebutton";
import {
  fetchSTJobTags,
  fetchSTTechnicians,
  updateOrganization,
} from "../../redux/organizationSlice";
import { filterOption } from "../../utils/filterOption";
import { exclusiveWeekendOrgs } from "../../utils/constants";
import CustomCssTransition from "../UI/CustomCssTransition/customCssTransition";

const { Title } = Typography;

function JobSettings() {
  const [defaultDispatchText, setDefaultDispatchText] = useState({
    header: "",
    body: "",
    checkbox: "",
  });
  const [weekendDispatchText, setWeekendDispatchText] = useState("");

  const [tagIds, setTagIds] = useState([]);
  const [job_assigning_technican_id, setJobAssigningTechnicanId] =
    useState("No Selection");
  const [preventNoOwnership, setPreventNoOwnership] = useState(false);

  const [isDirty, setIsDirty] = useState(false);
  const [showWeekend, setShowWeekend] = useState(false);

  const {
    loading,
    organizationData,
    serviceTitanTechnicians,
    serviceTitanJobTags,
  } = useSelector((state) => state?.organization);

  const dispatch = useDispatch();

  useEffect(() => {
    setDefaultDispatchText({
      header: organizationData?.default_dispatch_header ?? "",
      body: organizationData?.default_dispatch_body ?? "",
      checkbox: organizationData?.default_dispatch_text ?? "",
    });
    setWeekendDispatchText(organizationData?.weekend_dispatch_text ?? "");
    setShowWeekend(organizationData?.showWeekend ?? false);
    setPreventNoOwnership(organizationData?.preventNoOwnership ?? false);
    setJobAssigningTechnicanId(
      organizationData?.job_assigning_technican_id ?? "No Selection"
    );
    setTagIds(organizationData?.tagIds || []);
    dispatch(fetchSTTechnicians(organizationData?._id || ""));
  }, [organizationData]);

  const onHeaderChange = (e) => {
    setDefaultDispatchText({
      ...defaultDispatchText,
      [e.target.name]: e.target.value,
    });
    setIsDirty(true);
  };

  const handleSubmit = () => {
    const { header, body, checkbox } = defaultDispatchText;
    let updateOrganizationData = {
      ...organizationData,
      job_assigning_technican_id: job_assigning_technican_id,
      preventNoOwnership: preventNoOwnership,
      tagIds: tagIds,
      default_dispatch_header: header,
      default_dispatch_body: body,
      default_dispatch_text: checkbox,
      showWeekend: showWeekend,
      weekend_dispatch_text: weekendDispatchText,
    };

    dispatch(
      updateOrganization({
        id: organizationData._id,
        payload: updateOrganizationData,
        page: "Job Settings"
      })
    );
    setIsDirty(false);
  };

  const syncJobTags = () => {
    dispatch(fetchSTJobTags(organizationData._id));
  };

  const handleWeekendToggle = (checked) => {
    setShowWeekend(checked);
    setIsDirty(true);
  };

  const TechniciansList = [
    {
      label: "No Selection",
      value: "No Selection",
    },
    ...(serviceTitanTechnicians
      ?.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      })
      ?.filter((items) => items?.label) || []),
  ];
  return (
    <div className="w-full flex flex-col">
      <Spin spinning={loading} fullscreen />
      <Flex align="center" gap={14} style={{ marginBottom: "20px" }}>
        <SettingOutlined style={{ fontSize: 20 }} />
        <Title level={4} style={{ margin: 0 }}>
          Job Settings
        </Title>
      </Flex>

      <div className="flex flex-col items-start gap-6 bg-white shadow-md rounded-lg p-6 mb-6 w-full">
        <label className="text-base font-semibold text-black">
          Default Dispatch Text
        </label>

        <Tooltip
          styles={{ width: "100%" }}
          title={null}
        >
          <Input
            styles={{ width: "100%" }}
            showCount
            maxLength={100}
            name="header"
            value={defaultDispatchText?.header}
            onChange={onHeaderChange}
            placeholder="Header"
            size="middle"
          />
        </Tooltip>

        <Tooltip
          styles={{ width: "100%" }}
          title={null}
        >
          <TextArea
            maxLength={250}
            name="body"
            value={defaultDispatchText?.body}
            onChange={onHeaderChange}
            placeholder="Body"
            size="middle"
            autoSize={{
              minRows: 1,
              maxRows: 2,
            }}
            showCount
          />
        </Tooltip>

        <Tooltip
          styles={{ width: "100%" }}
          title={null}
        >
          <Input
            showCount
            maxLength={25}
            name="checkbox"
            value={defaultDispatchText?.checkbox}
            onChange={onHeaderChange}
            placeholder="Checkbox"
            size="middle"
          />
        </Tooltip>
      </div>

      {exclusiveWeekendOrgs.includes(organizationData?._id) && (
        <div className="flex flex-col items-start gap-4 bg-white shadow-md rounded-lg p-6 mb-6">
          <div className="flex items-center gap-4 w-full">
            <label className="text-base font-semibold text-black">
              Enable Weekend Dispatch Fee
            </label>
            <Switch checked={showWeekend} onChange={handleWeekendToggle} />
          </div>
          <CustomCssTransition
            in={showWeekend}
            timeout={300}
            classNames="glimpse"
            unmountOnExit
          >
            <div className="flex flex-col items-start gap-8 bg-white shadow-md rounded-lg p-6 mb-6 w-full">
              <label className="text-base font-semibold text-black">
                Weekend Dispatch Text
              </label>
              <Input
                showCount
                maxLength={200}
                name="text"
                value={weekendDispatchText}
                onChange={(e) => {
                  setWeekendDispatchText(e.target.value);
                  setIsDirty(true);
                }}
                placeholder="Text"
                size="middle"
              />
            </div>
          </CustomCssTransition>
        </div>
      )}

      <div className="flex flex-col items-start gap-2 bg-white shadow-md rounded-lg p-6 mb-6">
        <label className="text-base font-semibold text-black">Job Tags</label>
        <div className="w-full flex flex-col items-start gap-2">
          <Tooltip
            styles={{ width: "100%" }}
            title={null}
          >
            <Select
              showSearch
              mode="multiple"
              placeholder="Select job tags"
              style={{ width: "100%", padding: "5px" }}
              loading={loading}
              options={
                serviceTitanJobTags?.data
                  ? serviceTitanJobTags.data.map((item) => ({
                      label: item.name,
                      value: item.id,
                      desc: item.name,
                    }))
                  : []
              }
              filterOption={filterOption}
              defaultValue={
                serviceTitanJobTags?.data
                  ? serviceTitanJobTags.data
                      .filter((item) => tagIds?.includes(item.id))
                      .map((data) => {
                        return data.id;
                      }) ?? []
                  : []
              }
              value={
                serviceTitanJobTags?.data
                  ? serviceTitanJobTags.data
                      .filter((item) => tagIds?.includes(item.id))
                      .map((data) => {
                        return data.id;
                      }) ?? []
                  : []
              }
              onChange={(value) => {
                setTagIds([...value]);
                setIsDirty(true);
              }}
              size="middle"
            ></Select>
          </Tooltip>
          <Button
            style={{ width: "100%", marginTop: 10 }}
            title="Sync Job Tags"
            loading={loading}
            size="middle"
            onClick={syncJobTags}
          >
            Sync Job Tags
          </Button>
          <p className="text-sm text-gray-400">
            Sync job tags to ensure you have the latest updates from
            ServiceTitan, including any changes in tag status (activate or
            deactivate).
          </p>
        </div>
      </div>
      <div className="flex flex-col items-start gap-4 bg-white shadow-md rounded-lg p-6 mb-6 w-full">
        <label className="text-base font-semibold text-black">
          Assign Default Technician
        </label>
        <Tooltip
          styles={{ width: "100%" }}
          title={null}
        >
          <Select
            style={{ width: "100%" }}
            showSearch
            value={job_assigning_technican_id}
            onChange={(value) => {
              setJobAssigningTechnicanId(value);
              setIsDirty(true);
            }}
            options={TechniciansList}
            filterOption={filterOption}
            size="middle"
          />
        </Tooltip>
      </div>

      <div className="flex items-center gap-4 bg-white shadow-md rounded-lg p-6 mb-6 w-full">
        <label className="font-medium text-base text-black">
          Disallow Non-Home Owners From Booking Jobs
        </label>
        <Tooltip
          styles={{ width: "100%" }}
          title={null}
        >
          <Switch
            checked={preventNoOwnership}
            onChange={(value) => {
              setPreventNoOwnership(value);
              setIsDirty(true);
            }}
          />
        </Tooltip>
      </div>
      <SaveButton onSubmit={handleSubmit} isDirty={isDirty} />
    </div>
  );
}

export default JobSettings;
