import React, { useState, useEffect } from "react";
import { Button, Select, Typography } from "antd";
import debounce from "lodash/debounce";
import axiosInstance from "../../../redux/apiAxios";

const { Option } = Select;

const FilterControls = ({
  selectedSource,
  handleSourceChange,
  selectedMedium,
  handleMediumChange,
  activeTab,
  handleClearFilters,
  organizationsList,
  selectedOrganizations,
  handleOrganizationChange,
}) => {
  const [localSelectedOrgs, setLocalSelectedOrgs] = useState(
    selectedOrganizations
  );
  const [leads_sources, setleadssources] = useState([]);
  const [lead_medium, setleadmedium] = useState([]);

  useEffect(() => {
    setLocalSelectedOrgs(selectedOrganizations);

    async function getLeadsMediumsAndSources() {
      try {
        const response = await axiosInstance.get("leads_mediums_and_sources");
        const data = await response.data?.[0];
        setleadssources(data.sources || []);
        setleadmedium(data.mediums || []);
      } catch (error) {
        console.error("Error fetching leads mediums and sources:", error);
      }
    }

    getLeadsMediumsAndSources();
  }, [selectedOrganizations]);

  const debouncedOrgChange = debounce((values) => {
    handleOrganizationChange(values);
  }, 300);

  const handleLocalOrgChange = (values) => {
    setLocalSelectedOrgs(values);
    debouncedOrgChange(values);
  };

  return (
    <div
      style={{
        display: "flex",
        marginBottom: "20px",
        gap: "16px",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: "8px", fontWeight: "medium" }}>
          Source:
        </span>
        <Select
          value={selectedSource || null}
          onChange={handleSourceChange}
          style={{ minWidth: "150px" }}
          allowClear
          showSearch
          placeholder="All Sources"
          filterOption={(input, option) =>
            (option?.children || "").toLowerCase().includes(input.toLowerCase())
          }
        >
          {leads_sources.map((source) => (
            <Option key={source} value={source}>
              {source}
            </Option>
          ))}
        </Select>
      </div>

      {activeTab === "table" && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: "8px", fontWeight: "medium" }}>
            Medium:
          </span>
          <Select
            value={selectedMedium || null}
            onChange={handleMediumChange}
            style={{ minWidth: "150px" }}
            allowClear
            showSearch
            placeholder="All Mediums"
            filterOption={(input, option) =>
              (option?.children || "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {lead_medium.map((medium) => (
              <Option key={medium} value={medium}>
                {medium}
              </Option>
            ))}
          </Select>
        </div>
      )}

      {activeTab === "analysis" && (
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: "8px", fontWeight: "medium" }}>
              Organizations:
            </span>
            <Select
              mode="tags"
              placeholder="Select Organizations"
              value={localSelectedOrgs}
              onChange={handleLocalOrgChange}
              style={{ minWidth: "250px" }}
              maxTagCount={2}
              maxTagTextLength={10}
              optionFilterProp="children"
              allowClear
              showSearch
              filterOption={(input, option) =>
                (option?.children || "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {[...organizationsList]
                .sort((a, b) =>
                  a.organization_name
                    .charAt(0)
                    .localeCompare(b.organization_name.charAt(0))
                )
                .map((org) => (
                  <Option key={org._id} value={org._id}>
                    {org.organization_name || "Unnamed Organization"}
                  </Option>
                ))}
            </Select>
          </div>
        </div>
      )}

      {(selectedSource || selectedMedium) && (
        <Button onClick={handleClearFilters} size="small">
          Clear Filters
        </Button>
      )}
    </div>
  );
};

export default FilterControls;
