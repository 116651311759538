import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Input,
  List,
  message,
  Modal,
  Select,
  Spin,
  Typography,
  Upload,
  Alert,
} from "antd";
import { DeleteFilled, InsertRowLeftOutlined, PlusOutlined, } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  updateOrganization,
  updateOrganizationDomains,
} from "../../redux/organizationSlice";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { getlongLat, getTimeZone } from "../../utils/utilFunctions";
import { filterOption } from "../../utils/filterOption";
import { states } from "../../utils/states";
import moment from "moment-timezone";
import Item from "antd/es/list/Item";
import { MAX_FILE_HEIGHT, MAX_FILE_WIDTH } from "../../utils/constants";
import SaveButton from "../../components/UI/savebutton";

const { Title } = Typography;

function CompanyProfile() {
  const [organizationDomain, setOrganizationDomain] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [primaryColor, setPrimaryColor] = useState("#FF5959");
  const [inputOrganizationDomain, setInputOrganizationDomain] = useState("");
  const [organizationCity, setOrganizationCity] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [organizationWebsite, setOrganizationWebsite] = useState("");
  const [organizationState, setOrganizationState] = useState("");
  const { loading, organizationData } = useSelector(
    (state) => state?.organization
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setOrganizationDomain(organizationData?.organization_domains || []);
    setPrimaryColor(organizationData?.primaryColor || "#FF5959");
    setOrganizationCity(organizationData?.organization_city || "");
    setOrganizationName(organizationData?.organization_name || "");
    setOrganizationWebsite(organizationData?.organization_webAddress || "");
    setOrganizationState(organizationData?.organization_state || "");
    console.log(organizationData?.organization_timeZone)
    setTimeZone(organizationData?.organization_timeZone || "");

    setFileList(
      organizationData?.organization_logo
        ? [organizationData?.organization_logo]
        : []
    );
  }, [organizationData]);

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      ...organizationData,
      organization_logo: fileList[0],
      organization_name: organizationName,
      organization_webAddress: organizationWebsite,
      organization_domains: organizationDomain,
      organization_city: organizationCity,
      organization_state: organizationState,
      organization_timeZone: timeZone,
      primaryColor,
    };
    setInputOrganizationDomain("");
    dispatch(updateOrganization({ id: organizationData._id, payload: data,page: "Company Profile" }));
  };

  const TimeZones =
    moment?.tz?.zonesForCountry("US").map((item) => ({
      label: item,
      value: item,
    })) || [];

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    const isLt2M = file.size / 1024 < 100;
    if (!isLt2M) {
      message.error("Image must smaller than 100KB!");
    }

    return isJpgOrPng && isLt2M;
  };

  const handleCancel = () => setPreviewOpen(false);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file?.url && !file?.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = async ({ file }) => {
    const preview = await getBase64(file.originFileObj);
    file["preview"] = preview;
    setFileList([file]);
  };

  return (
    <form style={{ width: "100%" }}>
      <Spin spinning={loading} fullscreen />
      <Flex align="center" gap={10} style={{ marginBottom: "20px" }}>
        <InsertRowLeftOutlined style={{ fontSize: 20 }} />
        <Title level={3} style={{ margin: 0, color: "black" }}>
          Company Profile
        </Title>
      </Flex>

      <div className="bg-white shadow-md rounded-lg p-6 mb-6 w-full">
        <Title level={4} style={{ color: "#333" }}>
          Company Details
        </Title>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-4">
          <div className="flex flex-col items-start gap-2">
            <label style={{ fontWeight: "bold" }}>Organization Name</label>
            <Input
              showCount
              type="text"
              maxLength={50}
              placeholder="Enter Organization Name"
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
              size="large"
            />
          </div>

          <div className="flex flex-col items-start gap-2">
            <label style={{ fontWeight: "bold" }}>
              Organization Web Address
            </label>
            <Input
              showCount
              type="url"
              maxLength={50}
              placeholder="Enter Website Address"
              value={organizationWebsite}
              onChange={(e) => setOrganizationWebsite(e.target.value)}
              size="large"
            />
          </div>

          <div className="w-full flex flex-col items-start gap-2">
            <label style={{ fontWeight: "bold" }}>Additional Domains</label>
            <Input
              type="text"
              maxLength={50}
              placeholder="Add Organization Domains"
              value={inputOrganizationDomain}
              onChange={(e) => setInputOrganizationDomain(e.target.value)}
              style={{
                width: "100%",
                padding: "8px",
                border: "1px solid #d9d9d9",
                borderRadius: "4px",
              }}
              showCount
            />
            <Button
              type="primary"
              onClick={() =>
                dispatch(
                  updateOrganizationDomains({
                    id: organizationData._id,
                    domain: inputOrganizationDomain,
                  })
                )
              }
              style={{ marginTop: 10 }}
            >
              Add
            </Button>
            <List
              size="small"
              bordered
              dataSource={organizationDomain}
              style={{
                maxHeight: 300,
                overflow: "auto",
                width: "100%",
              }}
              renderItem={(item) => (
                <List.Item key={item}>
                  <List.Item.Meta title={item} />
                  <DeleteFilled
                    style={{ color: "#ff4d4f" }}
                    height="1em"
                    onClick={() => {
                      const newOrganizationDomain = organizationDomain.filter(
                        (domain) => domain !== item
                      );
                      dispatch(
                        updateOrganization({
                          id: organizationData._id,
                          payload: {
                            ...organizationData,
                            organization_domains: newOrganizationDomain,
                          },
                        })
                      );
                    }}
                  />
                </List.Item>
              )}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: "1" }}>
                <p
                  style={{
                    marginBottom: "0.5rem",
                    fontSize: "14px",
                    color: "#999",
                  }}
                >
                  e.g. ( <a href="#">https://samplewebsite.com</a> )
                </p>
                <Alert
                  style={{ padding: "0.5rem", marginBottom: "0.5rem" }}
                  message={
                    <div>
                      <span>Please enter the website addresses</span>{" "}
                      <strong>exactly as it appears on your websites</strong>.{" "}
                      <span>
                        This is important for the scheduler to function
                        correctly.
                      </span>
                    </div>
                  }
                  type="warning"
                  showIcon
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col items-start gap-2">
            <label style={{ fontWeight: "bold" }}>Operational City</label>
            <ReactGoogleAutocomplete
              apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              defaultValue={organizationData?.organization_city || ""}
              onPlaceSelected={async (place) => {
                try {
                  const { lat, lng } = await getlongLat(
                    place.formatted_address
                  );
                  const tz = await getTimeZone(lat, lng);
                  setTimeZone(tz);
                } catch (err) {
                  console.log(err);
                }
                setOrganizationCity(place.formatted_address);
              }}
              className="address-autofill"
              options={{
                componentRestrictions: { country: "us" },
                fields: ["address_components", "formatted_address"],
                strictBounds: false,
                types: ["locality"],
              }}
            />

            <label style={{ fontWeight: "bold" }}>Operational State</label>
            <Select
              showCount
              value={organizationState}
              onChange={(value) => setOrganizationState(value)}
              style={{ width: "100%" }}
              showSearch
              options={states.map((item) => ({
                label: item.label,
                value: item.value,
              }))}
              filterOption={filterOption}
              size="large"
            />

            <label style={{ fontWeight: "bold" }}>Time Zone</label>
            <Select
              showCount
              style={{ width: "100%" }}
              showSearch
              options={TimeZones}
              value={timeZone}
              onSelect={(value) => setTimeZone(value)}
              size="large"
            />
          </div>

          <div className="flex flex-col items-start gap-2">
            <label style={{ fontWeight: "bold" }}>Upload Logo</label>
            <Item
              label="Upload Logo"
              name="organization_logo"
              valuePropName="fileList"
              extra={
                <div>
                  <div>
                    Files must be less than: <strong>100KB</strong>
                  </div>
                  <div>
                    Allowed file types: <strong>jpg, png, jpeg</strong>
                  </div>
                  <div>
                    Preferred dimensions:{" "}
                    <strong>
                      {MAX_FILE_WIDTH} x {MAX_FILE_HEIGHT}
                    </strong>
                  </div>
                </div>
              }
            >
              <Upload
                action="https://file.io"
                listType="picture-card"
                fileList={fileList ?? []}
                beforeUpload={beforeUpload}
                onPreview={handlePreview}
                onChange={handleChange}
                accept=".png,.jpg,.jpeg"
                maxCount={1}
              >
                <div>
                  <PlusOutlined />
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    Upload
                  </div>
                </div>
              </Upload>
              <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="organization_logo"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  src={previewImage}
                />
              </Modal>
            </Item>
          </div>

          <div className="flex flex-col items-start gap-2">
            <label style={{ fontWeight: "bold" }}>Primary Color</label>
            <Input
              value={primaryColor}
              type="color"
              onChange={(e) => setPrimaryColor(e.target.value)}
              defaultValue="#FF5959"
            />
          </div>
        </div>
      </div>

      <SaveButton onSubmit={onSubmit} isDirty={true} />
    </form>
  );
}

export default CompanyProfile;
