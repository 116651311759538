import React from 'react';
import { Card, Tooltip, Divider } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Cell,
  LabelList
} from "recharts";

const CombinedAnalysisChart = ({ sourceData, sourceLoading }) => {
  if (!sourceData || sourceData.length === 0) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        No data available
      </div>
    );
  }
  const sortedSourceData = [...sourceData].sort((a, b) => 
    (b.total_bookings + b.total_abandonments) - (a.total_bookings + a.total_abandonments)
  );

  const topSources = sortedSourceData.slice(0, 10);
  const chartData = sourceData.map(item => ({
    lead_medium: item.lead_medium || '(not set)',
    booking_rate: item.booking_rate_percentage || 0,
    total_leads: item.total_bookings + item.total_abandonments
  }))
  const sortedRateData = [...chartData].sort((a, b) => b.booking_rate - a.booking_rate);
  const topMediums = sortedRateData.slice(0, 10);
  const getColorByRate = (rate) => {
    if (rate >= 70) return '#52c41a';
    if (rate >= 40) return '#faad14';
    return '#ff4d4f';
  };

  const totalBookings = sourceData.reduce((sum, item) => sum + item.total_bookings, 0);
  const totalLeads = sourceData.reduce((sum, item) => sum + (item.total_bookings + item.total_abandonments), 0);
  const overallRate = totalLeads > 0 ? (totalBookings / totalLeads) * 100 : 0;

  return (
    <div>
      <Card 
        title="Source Analysis" 
        loading={sourceLoading}
        extra={
          <Tooltip title="This chart shows the distribution of bookings and abandonments by medium for the selected date range and source.">
            <InfoCircleOutlined style={{ fontSize: '16px' }} />
          </Tooltip>
        }
      >
        <div style={{ marginBottom: '20px' }}>
          <p>
            This chart shows the distribution of bookings and abandonments by medium for the selected date range and source.
          </p>
        </div>
        <div style={{ width: '100%', height: 400 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={topSources}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 70,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="lead_medium" 
                angle={-45} 
                textAnchor="end"
                height={70}
                interval={0}
              />
              <YAxis />
              <RechartsTooltip 
                formatter={(value, name) => [value, name === 'total_bookings' ? 'Bookings' : 'Abandonments']}
                labelFormatter={(label) => `Medium: ${label}`}
              />
              <Legend />
              <Bar dataKey="total_bookings" name="Bookings" fill="#3B7AD9" />
              <Bar dataKey="total_abandonments" name="Abandonments" fill="#FF4D4F" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Card>

      <Divider />

      <Card 
        title="Booking Rate Analysis" 
        loading={sourceLoading}
        extra={
          <Tooltip title="This analysis shows the booking rate percentage for each medium. A higher booking rate indicates better conversion.">
            <InfoCircleOutlined style={{ fontSize: '16px' }} />
          </Tooltip>
        }
      >
        <div style={{ marginBottom: '20px' }}>
          <p>
            This chart shows the booking rate for each medium, sorted by highest conversion rate first.
            The overall booking rate across all mediums is <strong>{overallRate.toFixed(2)}%</strong>.
          </p>
        </div>
        <div style={{ width: '100%', height: 400 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={topMediums}
              layout="vertical"
              margin={{
                top: 20,
                right: 50,
                left: 80,
                bottom: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                type="number" 
                domain={[0, 100]} 
                tickFormatter={(value) => `${value}%`}
                label={{ value: 'Booking Rate (%)', position: 'insideBottom', offset: -10 }}
              />
              <YAxis 
                type="category" 
                dataKey="lead_medium" 
                width={80}
              />
              <RechartsTooltip 
                formatter={(value) => [`${value.toFixed(2)}%`, 'Booking Rate']}
                labelFormatter={(label) => `Medium: ${label}`}
              />
              <Bar 
                dataKey="booking_rate" 
                name="Booking Rate" 
                radius={[0, 4, 4, 0]}
              >
                {topMediums.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={getColorByRate(entry.booking_rate)} />
                ))}
                <LabelList 
                  dataKey="booking_rate" 
                  position="insideRight"
                  formatter={(value) => `${value.toFixed(2)}%`}
                  style={{ fill: '#fff', fontWeight: 'bold', textShadow: '0px 0px 2px rgba(0,0,0,0.7)' }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div style={{ marginTop: '20px', textAlign: 'center', fontSize: '12px', color: '#888' }}>
          <span style={{ marginRight: '15px' }}>
            <span style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: '#52c41a', marginRight: '5px' }}></span>
            High (≥70%)
          </span>
          <span style={{ marginRight: '15px' }}>
            <span style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: '#faad14', marginRight: '5px' }}></span>
            Medium (40-69%)
          </span>
          <span>
            <span style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: '#ff4d4f', marginRight: '5px' }}></span>
            Low (&lt;40%)
          </span>
        </div>
      </Card>
    </div>
  );
};

export default CombinedAnalysisChart; 