import React, { useEffect, useState } from "react";
import { Button, Flex, Input, List, Spin, Tooltip, Typography } from "antd";
import { DeleteFilled, GlobalOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { updateOrganization } from "../../redux/organizationSlice";
import Item from "antd/es/list/Item";
import SaveButton from "../../components/UI/savebutton";
const { Title } = Typography;

function ServiceArea() {
  const [currentZip, setCurrentZip] = useState("");
  const [zipCodesList, setZipCodesList] = useState([]);

  const { loading, organizationData } = useSelector(
    (state) => state?.organization
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setZipCodesList(organizationData?.zipCodes || []);
  }, [organizationData]);

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      ...organizationData,
      zipCodes: zipCodesList,
    };
    dispatch(updateOrganization({ id: organizationData._id, payload: data,page: "Service Area" }));
  };

  const onRemoveZip = (value) => {
    const list = zipCodesList.filter((e) => e !== value);
    setZipCodesList([...list]);
  };

  const handleAddZipCodes = () => {
    let zipCodesArray = currentZip.split(",");
    zipCodesArray = zipCodesArray.map((item) => item.trim());

    const concatenatedArray = zipCodesArray.concat(zipCodesList);
    const uniqueArray = Array.from(new Set(concatenatedArray));

    setZipCodesList([...uniqueArray]);
    setCurrentZip("");
  };

  return (
    <form style={{ width: "100%" }}>
      <Spin spinning={loading} fullscreen />
      <Flex align="center" gap={10} style={{ marginBottom: "20px" }}>
        <img
          src={require("../../assets/Images/map-logo.png")}
          height={20}
          width={20}
          alt="map-logo"
          style={{
            verticalAlign: "middle",
            display: "inline-block",
          }}
        />
        <Title level={3} style={{ margin: 0, color: "black" }}>
          Service Area
        </Title>
      </Flex>

      <div className="bg-white shadow-md rounded-lg p-6 mb-6  flex flex-col gap-6 items-start w-full">
        <div className="flex flex-col gap-2 w-full items-start">
          <label style={{ fontWeight: "bold" }}>Enter Zip Code</label>
          <Item
            name="zipCodes"
            label="Operational Zip Codes"
            className="profile_input_wrapper"
          >
            <Tooltip title={null}>
              <Input
                value={currentZip}
                onChange={(e) => setCurrentZip(e.target.value)}
                placeholder="Enter Zip Code"
                size="large"
              />
            </Tooltip>
            <Button style={{ marginTop: 10 }} onClick={handleAddZipCodes}>
              Add Zip Codes
            </Button>
          </Item>
        </div>

        <div className="flex flex-col gap-2 w-full items-start">
          <label style={{ fontWeight: "bold" }}>Operational Zip Codes</label>
          <List
            size="small"
            bordered
            dataSource={zipCodesList}
            className="profile_input_wrapper"
            style={{
              maxHeight: 300,
              overflow: "auto",
              width: "100%",
            }}
            renderItem={(item) => (
              <List.Item key={item}>
                <List.Item.Meta title={item} />
                <DeleteFilled
                  style={{ color: "#ff4d4f" }}
                  height="1em"
                  onClick={() => onRemoveZip(item)}
                />
              </List.Item>
            )}
          />
        </div>
      </div>

      <SaveButton onSubmit={onSubmit} isDirty={true} />
    </form>
  );
}

export default ServiceArea;
