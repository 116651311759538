import React, { useState,memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popconfirm, Spin, Table, Typography } from "antd";
import { DeleteFilled, QuestionCircleOutlined } from "@ant-design/icons";
import { deleteOfferedJobs} from "../../redux/jobsSlice";

const { Text } = Typography;

const JobsOfferingListing = () => {
	const { jobs = [], loading } = useSelector((state) => state.jobs);

	const dispatch = useDispatch();

	const [sortedInfo, setSortedInfo] = useState({});
	const handleChange = (pagination, filters, sorter) => {
		setSortedInfo(sorter);
	};
	const handleDelete = (id) => {
		dispatch(deleteOfferedJobs(id));
	};

	const columns = [
		{
			title: "Trade",
			dataIndex: "trade",
			key: "trade",
			sorter: (a, b) => a.trade.tradeName.length - b.trade.tradeName.length,
			sortOrder: sortedInfo.columnKey === "trade" ? sortedInfo.order : null,
			ellipsis: true,
			width: 200,
			render: (item) => <Text>{item?.tradeName}</Text>,
			filterSearch: true,
			filters: jobs.map(job => ({
				text: job.trade.tradeName,
				value: job.trade.tradeName,
			})).filter((v,i,a)=>a.findIndex(t=>(t.value===v.value))===i),
			onFilter: (value, record) => record.trade.tradeName.indexOf(value) === 0,
		},
		{
			title: "Job",
			dataIndex: "jobItem",
			key: "jobItem",
			sorter: (a, b) => a.jobItem.length - b.jobItem.length,
			sortOrder: sortedInfo.columnKey === "jobItem" ? sortedInfo.order : null,
			ellipsis: true,
			width: 200,
			filterSearch: true,
			filters: jobs.map(job => ({
				text: job.jobItem,
				value: job.jobItem,
			})).filter((v,i,a)=>a.findIndex(t=>(t.value===v.value))===i),
			onFilter: (value, record) => record.jobItem.indexOf(value) === 0,
		},
		{
			title: "Service Type",
			dataIndex: "serviceType",
			key: "serviceType",
			sorter: (a, b) => a.serviceType.length - b.serviceType.length,
			sortOrder:
				sortedInfo.columnKey === "serviceType" ? sortedInfo.order : null,
			ellipsis: true,
			width: 150,
			render: (item) => (
				<Text>
					{item?.charAt(0).toUpperCase() + item?.slice(1)}
				</Text>
			),
			filterSearch: true,
			filters: jobs.map(job => ({
				text: job.serviceType,
				value: job.serviceType,
			})).filter((v,i,a)=>a.findIndex(t=>(t.value===v.value))===i),
			onFilter: (value, record) => record.serviceType.indexOf(value) === 0,
		},
		{
			title: "Actions",
			key: "actions",
			width: 100,
			render: (_, record) => (
				  <Popconfirm
				  title="Delete Organization?"
				  description="Are you sure to delete this Organization?"
				  placement="left"
				  icon={
					  <QuestionCircleOutlined
						  style={{
							  color: "red",
						  }}
					  />
				  }
				  onConfirm={() => {
					handleDelete(record._id)
				}}
				  okText="Delete"
				  okType="danger"
				  cancelText="Cancel"
			  >
				  <DeleteFilled style={{ color: "red" }} />
			  </Popconfirm>
			),
		},

	];
	return (
		<>
			<Spin spinning={loading} fullscreen />
			<Table
				style={{ width: "100%" }}
				columns={columns}
				dataSource={jobs}
				onChange={handleChange}
				pagination={false}
			/>
		</>
	);
};

export default memo(JobsOfferingListing);
