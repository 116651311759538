import { Spin, Input, Tooltip as ANToolTip, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Line,
  ComposedChart,
} from "recharts";
import axiosInstance from "../../redux/apiAxios";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./Overview.css";
import { BiInfoCircle } from "react-icons/bi";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function Overview() {
  const { organizationData } = useSelector((state) => state.organization);

  const [displayCounters, setDisplayCounters] = useState({
    finishedCount: 0,
    abandonedCount: 0,
    totalUniqueFinish: 0,
    totalUniqueAbandon: 0,
    overallBookingRate: 0,
  });

  const [selectedRange, setSelectedRange] = useState([
    {
      startDate: dayjs().startOf("month").toDate(),
      endDate: dayjs().endOf("month").toDate(),
      key: "selection",
    },
  ]);

  const [mergedData, setMergedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pickerOpen, setPickerOpen] = useState(false);

  const pickerRef = useRef(null);

  useEffect(() => {
    if (organizationData) {
      const { startDate, endDate } = selectedRange[0];
      fetchAnalytics([startDate, endDate]);
    }
  }, [organizationData, selectedRange]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setPickerOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchAnalytics = async ([start, end]) => {
    const start_date = dayjs(start)
      .startOf("day")
      .format("YYYY-MM-DD");
    const end_date = dayjs(end).endOf("day").format("YYYY-MM-DD");
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/scheduler_type?organizationId=${organizationData?._id}&startDate=${start_date}&endDate=${end_date}&profile_id=${organizationData?.apiKeys?.whatConverts_profileId}`
      );

      const { ByDateMetrics, OverallMetrics } = response.data || {};

      console.log("fetch days difference",start_date,end_date)

      // console.log("response")
      // console.log(response.data)

      animateCounters({
        totalUniqueFinish: OverallMetrics?.TotalUniqueFinish || 0,
        totalUniqueAbandon: OverallMetrics?.TotalUniqueAbandon || 0,
        overallBookingRate: OverallMetrics?.OverallBookingRate || 0,
      });

      setMergedData(ByDateMetrics);
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    } finally {
      setLoading(false);
    }
  };

  const animateCounters = (targetCounters) => {
    const duration = 1000;
    const frameRate = 30;
    const totalFrames = Math.round((duration / 1000) * frameRate);

    const increment = {
      totalUniqueFinish: targetCounters.totalUniqueFinish / totalFrames,
      totalUniqueAbandon: targetCounters.totalUniqueAbandon / totalFrames,
      overallBookingRate: targetCounters.overallBookingRate / totalFrames,
    };

    let frame = 0;
    const interval = setInterval(() => {
      frame++;
      setDisplayCounters((prev) => ({
        totalUniqueFinish: Math.min(
          prev.totalUniqueFinish + increment.totalUniqueFinish,
          targetCounters.totalUniqueFinish
        ),
        totalUniqueAbandon: Math.min(
          prev.totalUniqueAbandon + increment.totalUniqueAbandon,
          targetCounters.totalUniqueAbandon
        ),
        overallBookingRate: Math.min(
          prev.overallBookingRate + increment.overallBookingRate,
          targetCounters.overallBookingRate
        ),
      }));

      if (frame >= totalFrames) {
        clearInterval(interval);
      }
    }, 1000 / frameRate);
  };

  const counters = [
    {
      name: "Unique Booking Rate (%)",
      count: displayCounters.overallBookingRate,
      tooltip:
      "This metric represents the percentage of unique customers who completed a booking out of the total unique customers who accessed the scheduler within the selected date range.It is calculated as: (Unique Customer Bookings ÷ Total Unique Customers Who Accessed the Scheduler) × 100%",
    },
    {
      name: "Unique Bookings",
      count: displayCounters.totalUniqueFinish,
      tooltip: "This metric represents the number of unique customers who have made bookings within the selected date range. Regardless of the number of jobs booked by a customer, each customer is counted only once. For example, if a customer books 10 jobs, they are counted as 1 unique booking.",
    },
    {
      name: "Unique Abandonments",
      count: displayCounters.totalUniqueAbandon,
      tooltip: "This metric represents the number of unique customers who opened the scheduler but did not complete a booking within the selected date range. Each customer is counted only once, even if they abandoned the scheduler multiple times.",
    },
  ];

  const formatSelectedRange = () => {
    const { startDate, endDate } = selectedRange[0];
    return `${dayjs(startDate).format("YYYY-MM-DD")} - ${dayjs(endDate).format(
      "YYYY-MM-DD"
    )}`;
    
  };

  const handleDateChange = (ranges) => {
    const startDate = dayjs(ranges.selection.startDate);
    const endDate = dayjs(ranges.selection.endDate);
    const diffDays = endDate.diff(startDate, 'day');
    if (diffDays > 400) {
      notification.error({
        message: 'Date Range Error',
        description: 'The selected date range cannot exceed 400 days.',
      });
    } else {
      setSelectedRange([ranges.selection]);
    }
  };

  return (
    <>
      <Spin spinning={loading} fullscreen />
      <div
        style={{ display: "flex", flexDirection: "column", padding: "20px" }}
      >
        <div style={{ alignSelf: "flex-end", marginBottom: "20px",width:"12rem",maxWidth:"12rem",textAlign:"center" }}>
          <div className="date-picker-container" ref={pickerRef}>
            <Input
             className="w-full cursor-pointer"
              value={formatSelectedRange()}
              onClick={() => setPickerOpen(!pickerOpen)}
              readOnly
            />
            {pickerOpen && (
              <div className="date-picker-dropdown">
                <DateRangePicker
                  ranges={selectedRange}
                  onChange={handleDateChange}
                />
              </div>
            )}
          </div>
        </div>

        <div className="w-full">
          <div className="flex md:flex-row flex-col md:justify-between md:items-center justify-center items-center gap-6 md:gap-0 p-10 overflow-x-auto">
            {counters.map((item, index) => (
              <div
                key={index}
                className="flex flex-col justify-center h-[300px] items-center bg-white shadow-lg rounded-lg text-center p-4 relative"
                style={{
                  height: "180px",
                  flex: "0 1 calc(100% / 3 - 20px)",
                }}
              >
                <ANToolTip title={item.tooltip} placement="top">
                  <BiInfoCircle className="absolute top-2 right-2 w-6 h-6 text-gray-500 cursor-pointer" />
                </ANToolTip>
                <div>
                  <h2 className="lg:text-2xl text-xl font-semibold">
                    {item.name === "Unique Booking Rate (%)"
                      ? `${item.count.toFixed(2)}%`
                      : Math.round(item.count)}
                  </h2>
                  <p className="lg:text-xl text-base">{item.name}</p>
                </div>
              </div>
            ))}
          </div>

          <div
            style={{
              width: "100%",
              height: "400px",
              marginBottom: "20px",
            }}
          >
            <h3 style={{ textAlign: "center",fontWeight: "bold" }}>
              Unique Bookings vs Unique Abandonments
            </h3>
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                width={500}
                height={400}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
                data={mergedData}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  tickFormatter={(dateStr) => dateStr}
                  label={{ value: "Date", position: "insideBottom", dy: 8, fontWeight: "bold" }}
                />
                <YAxis
                  yAxisId="left"
                  label={{
                    value: "Count",
                    angle: -90,
                    position: "insideLeft",
                    dy:0,
                    fontWeight: "bold",
                  }}
                  domain={[0, (dataMax) => dataMax + 1]}
                />
                <YAxis yAxisId="right" orientation="right" hide />
                <Tooltip />
                <Legend formatter={(value) => <span style={{ fontWeight: 'bold' }}>{value}</span>} />
                <Bar
                  yAxisId="left"
                  dataKey="finishedCount"
                  fill="#3B7AD9"
                  name="Unique Bookings"
                  animationEasing="ease"
                />
                <Bar
                  yAxisId="left"
                  dataKey="abandonedCount"
                  fill="red"
                  name="Unique Abandonments"
                />
                <Line
                  type="monotone"
                  dataKey="bookingRate"
                  isAnimationActive={true}
                  animationEasing="ease-in-out"
                  yAxisId="right"
                  stroke="black"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                  name="Unique Booking Rate (%)"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </>
  );
}
