/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  Table,
  Button,
  Space,
  Select,
  Input,
  DatePicker,
  Spin,
  Typography,
  Tag,
  message
} from "antd";
import dayjs from "dayjs";
import axiosInstance from "../../../redux/apiAxios";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, DownSquareFilled, FileExcelOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import debounce from "lodash/debounce";

import'./FollowUpLeads.css';
import Pusher from "pusher-js";

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Text } = Typography;

const statusOptions = [
  {
    label: "Booked",
    value: "Booked",
    color: "green",
    icon: <CheckCircleOutlined/>,
  },
  {
    label: "Contacted",
    value: "Contacted",
    color: "blue",
    icon: <SyncOutlined />,
  },
  {
    label: "Closed",
    value: "Closed",
    color: "red",
    icon: <CloseCircleOutlined />,
  },
  {
    label: "New Lead",
    value: "New Lead",
    color: "gold",
    icon: <ClockCircleOutlined />,
  },
];


const FollowUpLeads = ({followuptype}) => {
  const { organizationData } = useSelector((state) => state.organization);
  const { user } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);
  const searchInput = useRef(null);

  const [defaultRange, setDefaultRange] = useState([
    dayjs().startOf("day"),
    dayjs().endOf("day"),
  ]);
  const [activeTable] = useState(followuptype);
  const [messageApi, contextHolder] = message.useMessage();
  const [setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: false,
    },
  });
  const [isSaving, setIsSaving] = useState(false);
  
  useEffect(() => {
    if (organizationData?._id) {
      const pusher = new Pusher("425619d54c17cbd89114", {
        cluster: "ap2",
        encrypted: true,
      });
  
      pusher.connection.bind('connected', () => {
        console.log('Pusher connection established.');
      });
  
      pusher.connection.bind('disconnected', () => {
        console.log('Pusher connection lost.');
      });
  
      pusher.connection.bind('failed', () => {
        console.log('Pusher connection failed.');
      });
      const handleUnload = () => {
        console.log('Page is being unloaded, connection will be closed.');
        pusher.disconnect();
      };
  
      const channel = pusher.subscribe(`lead-channel-${organizationData._id}`);
      
      channel.bind("new-lead", (newLead) => {
        if (newLead.updatedLeadData.type === 'Scheduler_Finished') {
          setData((prevData) => (
            prevData.filter(lead => lead._id !== newLead.updatedLeadData._id)
          ));
        } else {
          if (newLead.updatedLeadData.leadType === followuptype) {
            setData((prevData) => {
              const leadIndex = prevData.findIndex(lead => lead._id === newLead.updatedLeadData?._id);
              if (leadIndex !== -1) {
                const updatedData = [...prevData];
                updatedData[leadIndex] = {
                  ...updatedData[leadIndex],
                  status: newLead?.updatedLeadData?.leadStatus,
                  notes: ""
                };
                return updatedData;
              } else {
                return [
                  {
                    ...newLead?.updatedLeadData,
                    status: newLead?.updatedLeadData?.leadStatus,
                    notes: ""
                  },
                  ...prevData
                ];
              }
            });
          }
        }
      
        success();
      });
      return () => {
        channel.unbind_all();
        window.removeEventListener('beforeunload', handleUnload);
        pusher.unsubscribe(`lead-channel-${organizationData._id}`);
        pusher.disconnect()
      };
    }
  }, [organizationData]);
  
  useEffect(() => {
    fetchleadbyStatus(defaultRange[0], defaultRange[1], followuptype);
  }, [activeTable,organizationData,followuptype]);

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Leads updated successfully!',
    });
  };

  const fetchLeads = async (startDate, endDate, tableType, page = 1) => {
    setFetchingData(true);
    try {
      const response = await axiosInstance.post("/org/fetch/followup", {
        organizationId: organizationData?._id,
        type: "Scheduler_Abandoned",
        leadType: tableType,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      });

      const transformedData = response.data.map((item) => ({
        ...item,
        status: item.leadStatus,
        notes: item.notes || "",
        updatedBy:user?.email,
      }));
      setData((prevState) => [...transformedData]);
      // setTableParams((prevState) => ({
      //   ...prevState,
      //   pagination: {
      //     ...prevState.pagination,
      //     current: page,
      //     total: response.data.total || 0,
      //   },
      // }));
      setFetchingData(false);
    } catch (error) {
      console.error("Error fetching leads:", error);
      setFetchingData(false);
    }
  };
  

  const fetchleadbyStatus = async (startDate, endDate, tableType, page = 1) => {
    setFetchingData(true);
    try {
      const response = await axiosInstance.post("/org/fetch/followup", {
        organizationId: organizationData?._id,
        type: "Scheduler_Abandoned",
        leadType: tableType,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      }); 
      const transformedData = response.data.map((item) => ({
        ...item,
        status: item.leadStatus,
        notes: item.notes || "",
      }));
      setData(transformedData);
      setTableParams((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          current: page,
          total: response.data.total || 0,
        },
      }));
      setFetchingData(false);
    } catch (error) {
      console.error("Error fetching leads:", error);
      setFetchingData(false);
    }
  };


  const handleDateChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      setDefaultRange([start, end]);
      fetchLeads(start, end, activeTable, 1);
    } else {
      setData([]);
    }
  };

  // const handleTableChange = (pagination) => {
  //   fetchLeads(
  //     defaultRange[0],
  //     defaultRange[1],
  //     activeTable,
  //     pagination.current
  //   );
  // };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, activeTable);
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(
      dataBlob,
      `${activeTable}_${dayjs().format("YYYYMMDD_HHmmss")}.xlsx`
    );
  };

  const handleStatusChange = (key, value,record) => {
    saveStatus(key, value,record);
  };

  const handleNotesChange = (key, value,record) => {
    setIsSaving(true);
    debouncedSaveNotes(key, value,record);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSaveNotes = useCallback(
    debounce((key, value,record) => {
      simulateSave(key, value,record);
    }, 1000),
    []
  );


  const saveStatus = async (key, value,record) => {
    setIsSaving(true)
      try {
        await axiosInstance.post("/org/update/followup", {
          organizationId: organizationData?._id,
          ...record,   
          notes:record.notes,
          leadStatus:value,     
          leadType:(value!=='New Lead' && value!=='Contacted' )?'Archived Lead':'New Lead',
          email:user?.email,
        });
        if ((followuptype === 'Archived Lead' && value === 'New Lead') || 
        (followuptype === 'Archived Lead' && value === 'Contacted') ||
        (followuptype === 'New Lead' && value === 'Closed') ||
        (followuptype === 'New Lead' && value === 'Booked')
      ) {
        setData((prevData)=>{
          return prevData.filter((item) => item._id !== record?._id);
        })
        }
      } catch (error) {
        console.error("Error updating lead status:", error);
      }
      finally {
        setIsSaving(false);
      }
  }

  const simulateSave = (key, value,record) => {
    setTimeout(async () => {
      try {
        await axiosInstance.post("/org/update/followup", {
          organizationId: organizationData?._id,
          ...record,        
          email:user?.email,
          notes:value,
          updatedBy:user?.email,
        });
        setData((prevData) => {
          return prevData.map((item) => 
            item._id === key ? { ...item, notes: value, updatedBy: user?.email } : item
          );
        });
      } catch (error) {
        console.error("Error updating lead status:", error);
      }
      setIsSaving(false);
    }, 1000);
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setTableParams((prevState) => ({
      ...prevState,
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    }));
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              setSelectedKeys([]);
              handleSearch([], confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
  });

  const archiveAll = async () => {
    try {
      let updatedData=[...data]
      let filtered=[...updatedData.map((item) => { return item.leadType === 'New Lead' ? { ...item, leadType: "Archived Lead",leadStatus:'Closed' } : item })];
      await axiosInstance.post("/org/update/followup", {
        organizationId: organizationData?._id,
        leadarchivearrays:filtered,
      });
      fetchLeads(defaultRange[0], defaultRange[1], activeTable);
    } catch (error) {
      console.error("Error archiving leads:", error);
    }
  }

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      filters: [
       { text: "Booked", value: "Booked" },
        { text: "Contacted", value: "Contacted" },
        { text: "Closed", value: "Closed" },
        { text: "New Lead", value: "New Lead" },
      ],
      onFilter: (value, record) =>
        record["status"].includes(value),
      render: (_, record) => (
        <Select
          defaultValue={record.status}
          onChange={(value) => handleStatusChange(record._id, value, record)}
          style={{ width: "100%",maxWidth:"300" }}
          
        >
          {statusOptions.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              <Tag  color={option.color} icon={option.icon}>
              {option.label}
              </Tag>
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      align: "center",
      render: (text, record) => (
        <TextArea
          defaultValue={text}
          rows={2}
          cols={60}
          autoSize={{ minRows: 2, maxRows: 4 }}
          placeholder="Add notes here..."
          style={{
            width: "300%",
            fontSize: "12px",
            lineHeight: "1.2",
            overflowY: "auto",
          }}
          onChange={(e) => {
            handleNotesChange(record.key, e.target.value, record);
          }}
        />
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      align: "center",
      style: { fontSize: "12px" }, 
      ...getColumnSearchProps("customerName"),
    },
    {
      title: "Phone",
      dataIndex: "customerPhone",
      key: "customerPhone",
      align: "center",
      style: { fontSize: "12px" },
      ...getColumnSearchProps("customerPhone"),
    },
    {
      title: "Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
      align: "center",
      style: { fontSize: "12px" },
      ...getColumnSearchProps("customerEmail"),
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      key: "updatedBy",
      align: "center",
      style: { fontSize: "12px !important", width: "50%"},
      ...getColumnSearchProps("updatedBy"),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      render: (date) => dayjs(date).format("ddd D MMMM YYYY, h:mm A"),
      style: { fontSize: "12px" },
      sorter: (a, b) => dayjs( a.updatedAt) -dayjs(b.updatedAt),
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
      key: "createdOn",
      align: "center",
      render: (date) => dayjs(date).format("ddd D MMMM YYYY, h:mm A"),
      style: { fontSize: "12px" },
      sorter: (a, b) => dayjs( a.createdOn) -dayjs(b.createdOn),
    },
  ];
  
  

  return (
    <Spin spinning={fetchingData}>
        {contextHolder}
      <Space style={{ marginBottom: 16 }}>
        {
          followuptype!=='New Lead' &&  <RangePicker
          value={defaultRange}
          onChange={handleDateChange}
          format="MMM D, YYYY"
        />
        }
        {
          followuptype!=='New Lead'? <Button
          icon={<FileExcelOutlined />}
          onClick={exportToExcel}
          style={{ backgroundColor: "#52c41a", color: "#fff", border: "none" }}
        >
          Export to Excel
        </Button> : <Button
          size={5}
          onClick={archiveAll}
          icon = {<DownSquareFilled/>}
          style={{ backgroundColor: "red", color: "#fff", border: "none",padding:'6px 4px',fontSize:"12px" }}
        >
          Archive All
        </Button>
        }
       
        <div style={{ display: "flex", alignItems: "center" }}>
          {isSaving ? (
            <>
              <Spin size="small" />
              <Text type="danger" style={{ marginLeft: 8 }}>
                Saving...
              </Text>
            </>
          ) : (
            <Text type="secondary">All changes saved.</Text>
          )}
        </div>
      </Space>
      <Table
        columns={columns}
        dataSource={[...data.map((item) => ({ ...item, key: item._id }))]}
      />
    </Spin>
  );
};

export default FollowUpLeads;
