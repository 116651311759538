import React from 'react';
import { Table, Tooltip, Input, Button } from 'antd';
import { FilterFilled, InfoCircleOutlined } from '@ant-design/icons';

const AnalyticsTable = ({ mergedData, loading }) => {
  const getNumberFilterProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder="Min"
          value={selectedKeys[0]?.min || ''}
          onChange={e => setSelectedKeys([{ min: e.target.value, max: selectedKeys[0]?.max || '' }])}
          style={{ marginBottom: 8, display: 'block', width: 120 }}
          type="number"
        />
        <Input
          placeholder="Max"
          value={selectedKeys[0]?.max || ''}
          onChange={e => setSelectedKeys([{ min: selectedKeys[0]?.min || '', max: e.target.value }])}
          style={{ marginBottom: 8, display: 'block', width: 120 }}
          type="number"
        />
        <Button
          type="primary"
          onClick={() => confirm()}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Filter
        </Button>
        <Button
          onClick={() => {
            clearFilters();
            confirm();
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      const { min, max } = value;
      const recordValue = record[dataIndex];
      
      if (min && max) {
        return recordValue >= Number(min) && recordValue <= Number(max);
      }
      if (min) {
        return recordValue >= Number(min);
      }
      if (max) {
        return recordValue <= Number(max);
      }
      return true;
    }
  });

  const getColorByRate = (rate) => {
    if (rate >= 70) return '#52c41a';
    if (rate >= 40) return '#faad14';
    return '#ff4d4f';
  };

  const columns = [
    {
      title: 'Organization',
      dataIndex: 'organization_name',
      key: 'organization_name',
      filters: mergedData
        .filter(item => item.organization_name !== 'Salman Testing')
        .map(item => ({
          text: item.organization_name,
          value: item.organization_name,
        })),
      onFilter: (value, record) => record.organization_name === value,
      filterSearch: true,
      sorter: (a, b) => a.organization_name.localeCompare(b.organization_name),
    },
    {
      title: 'Total Unique Leads',
      dataIndex: 'total_unique_leads',
      key: 'total_unique_leads',
      sorter: (a, b) => a.total_unique_leads - b.total_unique_leads,
      ...getNumberFilterProps('total_unique_leads'),
    },
    {
      title: 'Total Bookings',
      dataIndex: 'total_bookings',
      key: 'total_bookings',
      sorter: (a, b) => a.total_bookings - b.total_bookings,
      ...getNumberFilterProps('total_bookings'),
    },
    {
      title: 'Total Abandonments',
      dataIndex: 'total_abandonments',
      key: 'total_abandonments',
      sorter: (a, b) => a.total_abandonments - b.total_abandonments,
      ...getNumberFilterProps('total_abandonments'),
    },
    {
      title: 'Booking Rate (%)',
      dataIndex: 'booking_rate_percentage',
      key: 'booking_rate_percentage',
      sorter: (a, b) => a.booking_rate_percentage - b.booking_rate_percentage,
      ...getNumberFilterProps('booking_rate_percentage'),
      render: (value) => (
        <div style={{ 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <div style={{ 
            width: '70%', 
            background: '#f0f0f0', 
            borderRadius: '10px',
            overflow: 'hidden'
          }}>
            <div style={{ 
              width: `${Math.min(value, 100)}%`, 
              height: '20px', 
              background: getColorByRate(value),
              transition: 'width 0.5s ease'
            }} />
          </div>
          <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
            {value?.toFixed(2)}%
          </span>
        </div>
      ),
    },
  ];

  const tableStyle = {
    borderRadius: 12,
    border: '1px solid #e2e8f0',
    overflow: 'hidden',
    boxShadow: '0 2px 6px rgba(0,0,0,0.05)'
  };

  return (
    <Table
      dataSource={mergedData}
      columns={columns}
      rowKey="organization_name"
      pagination={false}
      bordered
      loading={loading}
      style={tableStyle}
      rowClassName="hover-row"
      scroll={{ x: true }}
      locale={{ emptyText: 'No Data' }}
      summary={(pageData) => {
        if (pageData.length === 0) return null;
        
        const sums = pageData.reduce((acc, curr) => ({
          total_unique_leads: acc.total_unique_leads + curr.total_unique_leads,
          total_bookings: acc.total_bookings + curr.total_bookings,
          total_abandonments: acc.total_abandonments + curr.total_abandonments,
        }), {
          total_unique_leads: 0,
          total_bookings: 0,
          total_abandonments: 0,
        });

        const count = pageData.length;
        const totalCompletionRate = (sums.total_bookings / sums.total_unique_leads) * 100;
        
        return (
          <Table.Summary fixed>
            <Table.Summary.Row className="total-row">
              <Table.Summary.Cell index={0}>
                Total ({count} rows)
              </Table.Summary.Cell>
              <Table.Summary.Cell style={{cursor:"pointer"}} index={1}>
                <Tooltip title="Sum of Total Unique Leads across all organizations in the selected date range">
                  {sums.total_unique_leads}
                  <InfoCircleOutlined style={{ marginLeft: '8px' }} />
                </Tooltip>
              </Table.Summary.Cell>
              <Table.Summary.Cell style={{cursor:"pointer"}} index={2}>
                <Tooltip title="Sum of Total Bookings across all organizations in the selected date range">
                  {sums.total_bookings}
                  <InfoCircleOutlined style={{ marginLeft: '8px' }} />
                </Tooltip>
              </Table.Summary.Cell>
              <Table.Summary.Cell style={{cursor:"pointer"}} index={3}>
                <Tooltip title="Sum of Total Abandonments across all organizations in the selected date range">
                  {sums.total_abandonments}
                  <InfoCircleOutlined style={{ marginLeft: '8px' }} />
                </Tooltip>
              </Table.Summary.Cell>
              <Table.Summary.Cell style={{cursor:"pointer"}} index={4}>
                <div style={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}>
                  <div style={{ 
                    width: '70%', 
                    background: '#f0f0f0', 
                    borderRadius: '10px',
                    overflow: 'hidden'
                  }}>
                    <div style={{ 
                      width: `${Math.min(totalCompletionRate, 100)}%`, 
                      height: '20px', 
                      background: getColorByRate(totalCompletionRate),
                      transition: 'width 0.5s ease'
                    }} />
                  </div>
                  <Tooltip title="Overall Booking Rate calculated as: (Total Bookings ÷ Total Unique Leads) × 100%">
                    <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                      {totalCompletionRate.toFixed(2)}%
                      <InfoCircleOutlined style={{ marginLeft: '8px' }} />
                    </span>
                  </Tooltip>
                </div>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        );
      }}
    />
  );
};

export default AnalyticsTable; 