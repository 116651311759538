// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-card {
    width: 100%;
    margin-bottom: 24px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.custom-row {
    margin-bottom: 10px;
}

.arrow-col {
    text-align: center;
    font-size: 24px;
}

.custom-button {
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/components/Organization/NavigationsFlow/RenamingTrades/renamingtrades.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;IACnB,wCAAwC;IACxC,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,wCAAwC;AAC5C","sourcesContent":[".custom-card {\n    width: 100%;\n    margin-bottom: 24px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    border-radius: 10px;\n}\n\n.custom-row {\n    margin-bottom: 10px;\n}\n\n.arrow-col {\n    text-align: center;\n    font-size: 24px;\n}\n\n.custom-button {\n    width: 100%;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
