import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { notification } from "antd";

import axios from "./apiAxios.js";

export const createTrade = createAsyncThunk(
	"trade/create",
	async ({ tradeName,organizationId }, { rejectWithValue }) => {
		try {
			const values = { tradeName,organizationId };
			const { data } = await axios.post(`/admin/trade/create`, values);
			notification.success({
				message: "Success",
				description: "Trade succesfully created",
				duration: 3,
			});
			return data;
		} catch (err) {
			notification.error({
				message: "Error",
				description: err.response.data.message,
				duration: 3,
			});
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const fetchTrades = createAsyncThunk(
	"trade/fetch",
	async (_, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(`/admin/trades/fetch`);
			return data;
		} catch (err) {
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const fetchServices = createAsyncThunk(
	"trade/services",
	async (_, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(`/admin/services/fetch`);
			return data;
		} catch (err) {
			return rejectWithValue(err.response.data.message);
		}
	}
);

export const tradeSlice = createSlice({
	name: "trades",
	initialState: {
		loading: false,
		trades: [],
		services: [],
		error: null,
	},
	extraReducers: {
		//create Trade
		[createTrade.pending]: (state) => {
			state.loading = true;
		},
		[createTrade.fulfilled]: (state, { payload }) => {
			state.trades = [...state.trades, payload];
			state.loading = false;
		},
		[createTrade.rejected]: (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		},

		//Fetch Trades
		[fetchTrades.pending]: (state) => {
			state.loading = true;
		},
		[fetchTrades.fulfilled]: (state, { payload }) => {
			state.trades = payload;
			state.loading = false;
		},
		[fetchTrades.rejected]: (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		},

		//Fetch Services
		[fetchServices.pending]: (state) => {
			state.loading = true;
		},
		[fetchServices.fulfilled]: (state, { payload }) => {
			state.services = payload;
			state.loading = false;
		},
		[fetchServices.rejected]: (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		},
	},
});

// Action creators are generated for each case reducer function

export const { authenticate } = tradeSlice.actions;

export default tradeSlice.reducer;
