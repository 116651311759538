import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  List,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Tooltip,
  Typography,
  Upload,
  message,
  notification,
  TimePicker,
  Table,
  Space,
  Alert,
  Flex,
} from "antd";
import { SettingOutlined, PlusOutlined, DeleteFilled } from "@ant-design/icons";

import moment from "moment-timezone";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import ReactGoogleAutocomplete from "react-google-autocomplete";

import {
  fetchSTBusinessUnits,
  fetchSTJobTags,
  fetchSTTechnicians,
  updateOrganization,
  updateOrganizationDomains,
} from "../../redux/organizationSlice";
import { states } from "../../utils/states";
import { getlongLat, getTimeZone } from "../../utils/utilFunctions";
import {
  exclusiveCampaignOrgs,
  exclusiveRedirectOrgs,
  exclusiveWeekendOrgs,
  MAX_FILE_HEIGHT,
  MAX_FILE_WIDTH,
} from "../../utils/constants";

const { Title } = Typography;
const { TextArea } = Input;
const { Item } = Form;

const OrganizationProfile = ({ isNewOrg = false }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [currentZip, setCurrentZip] = useState("");
  const [zipCodesList, setZipCodesList] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [holidayList, setHolidayList] = useState([]);
  const [blockAllday, setBlockAllday] = useState(true);
  const [tagIds, setTagIds] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [organizationDomain, setOrganizationDomain] = useState("");
  const [inputOrganizationDomain, setInputOrganizationDomain] = useState("");
  const [blockTimeRange, setBlockTimeRange] = useState("");
  const [selectedRange, setselectedRange] = useState(null);
  const [timeZone, setTimeZone] = useState("");
  const [preventNoOwnership, setPreventNoOwnership] = useState(false);
  const [isThankYouPage, setIsThankYouPage] = useState({
    thankyou_page: false,
    thankyou_page_url: "",
  });

  const {
    organizationData,
    serviceTitanTechnicians,
    serviceTitanJobTags,
    loading,
  } = useSelector((state) => state?.organization);

  const { currentOrganizationId } = useSelector((state) => state?.auth);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const initialValues = useMemo(
    () => ({
      organization_name: "",
      organization_logo: "",
      organization_state: "",
      organization_city: "",
      organization_webAddress: "",
      organization_domains: [],
      job_assigning_technican_id: "No Selection",
      default_dispatch_header: "",
      default_dispatch_body: "",
      default_dispatch_text: "",
      weekend_dispatch_header: "",
      weekend_dispatch_body: "",
      weekend_dispatch_text: "",
      isSTCapacitySet: false,
      isCapacityUnthrottled: false,
      primaryColor: "#FF5959",
      zipCodes: [],
      holidays: [],
      tagIds: [],
      prevent_noOwnership: false,
      thankyou_page: false,
      thankyou_page_url: "",
    }),
    []
  );

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    const isLt2M = file.size / 1024 < 100;
    if (!isLt2M) {
      message.error("Image must smaller than 100KB!");
    }

    return isJpgOrPng && isLt2M;
  };

  const handleCancel = () => setPreviewOpen(false);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = async ({ file }) => {
    const preview = await getBase64(file.originFileObj);
    file["preview"] = preview;
    setFileList([file]);
  };

  const onRemoveZip = (value) => {
    const list = zipCodesList.filter((e) => e !== value);
    setZipCodesList([...list]);
  };

  const onRemoveHoliday = (value) => {
    const list = holidays.filter((e, index) => index !== value);
    setHolidays([...list]);
  };

  const handleAddZipCodes = () => {
    let zipCodesArray = currentZip.split(",");
    zipCodesArray = zipCodesArray.map((item) => item.trim());

    const concatenatedArray = zipCodesArray.concat(zipCodesList);
    const uniqueArray = Array.from(new Set(concatenatedArray));

    setZipCodesList([...uniqueArray]);
    setCurrentZip("");
  };

  const handleSubmit = (formValues) => {
    if (
      organizationDomain?.length === 0 &&
      formValues.organization_webAddress === ""
    ) {
      notification.error({
        message: "Error",
        description: "Please enter the website addresses",
        duration: 3,
      });
      return;
    }
    if (
      isThankYouPage.thankyou_page &&
      isThankYouPage.thankyou_page_url === ""
    ) {
      notification.error({
        message: "Error",
        description: "Please enter the Thank You page URL",
        duration: 3,
      });
      return;
    }
    const primaryColor =
      typeof formValues.primaryColor === "string"
        ? formValues.primaryColor
        : formValues.primaryColor.toHexString();

    const values = {
      ...formValues,
      organization_logo: fileList[0],
      primaryColor,
      zipCodes: zipCodesList,
      holidays: holidays,
      tagIds: tagIds,
      showWeekend: showWeekend,
      organization_domains: organizationDomain,
      organization_timeZone: timeZone,
      preventNoOwnership: preventNoOwnership,
      thankyou_page_config: isThankYouPage,
    };

    if (
      values?.organization_logo?.error ||
      values?.organization_logo?.status === "error"
    ) {
      notification.error({
        message: "Error",
        description:
          "Error occured while uploading the image file. Try again with a different file.",
        duration: 3,
      });
    } else {
      setInputOrganizationDomain("");
      dispatch(
        updateOrganization({ id: organizationData._id, payload: values })
      );
    }
  };

  const [showWeekend, setShowWeekend] = useState(false);

  const handleWeekendToggle = (checked) => {
    setShowWeekend(checked);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => moment(date).format("DD MMM, YYYY"),
    },
    {
      title: "Time Range",
      dataIndex: "TimeRange",
      key: "TimeRange",
      render: (text, record) => record.TimeRange,
    },
    {
      title: "Block All Day",
      dataIndex: "BlockAllday",
      key: "BlockAllday",
      render: (BlockAllday) => (BlockAllday ? "Yes" : "No"),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => (
        <Button type="link" danger onClick={() => onRemoveHoliday(index)}>
          Delete
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (organizationData) {
      Object.keys(initialValues).forEach((item) => {
        if (item === "organization_logo" && organizationData[item] !== "") {
          setFileList([organizationData[item]]);
        } else {
          form.setFieldValue(
            `${item}`,
            organizationData[item] || initialValues[item]
          );
        }
      });
      setPreventNoOwnership(organizationData?.preventNoOwnership ?? false);
      setHolidays(organizationData?.holidays ?? []);
      setZipCodesList(organizationData?.zipCodes || []);
      setTagIds(organizationData?.tagIds || []);
      setOrganizationDomain(organizationData?.organization_domains || []);
      setTimeZone(organizationData?.organization_timeZone || "");
      setIsThankYouPage({
        thankyou_page:
          organizationData?.thankyou_page_config?.thankyou_page ?? false,
        thankyou_page_url:
          organizationData?.thankyou_page_config?.thankyou_page_url ?? "",
      });
      setShowWeekend(organizationData?.showWeekend ?? false);
    }
  }, [form, initialValues, organizationData]);

  useEffect(() => {
    dispatch(fetchSTBusinessUnits(currentOrganizationId));
    dispatch(fetchSTTechnicians(currentOrganizationId));
  }, [dispatch, currentOrganizationId]);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const TechniciansList =
    serviceTitanTechnicians?.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    }) || [];

  const syncJobTags = () => {
    dispatch(fetchSTJobTags(currentOrganizationId));
  };

  const TimeZones =
    moment?.tz?.zonesForCountry("US").map((item) => ({
      label: item,
      value: item,
    })) || [];

  console.log("no ownership");
  console.log(preventNoOwnership);
  return (
    <>
      <Spin spinning={loading} fullscreen />
      <Flex align="center" gap={10} style={{ marginBottom: "50px" }}>
        <SettingOutlined style={{ fontSize: 20 }} />
        <Title level={3} style={{ margin: 0 }}>
          Organization Settings
        </Title>
      </Flex>

      <Form
        labelCol={{
          span: 15,
        }}
        wrapperCol={{
          span: 20,
        }}
        layout="vertical"
        style={{
          maxWidth: "100%",
        }}
        initialValues={initialValues}
        form={form}
        onFinish={handleSubmit}
        autoComplete="new-state"
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Item
              label="Organization Name"
              name="organization_name"
              className="profile_input_wrapper"
              rules={[
                {
                  required: true,
                  message: "Please enter the organization name",
                },
              ]}
            >
              <Input
                showCount
                maxLength={50}
                placeholder="Organization Name"
                size="large"
              />
            </Item>
            <Divider />
            <Space
              direction="vertical"
              size="small"
              className="profile_input_wrapper"
            >
              <Item
                style={{ marginBottom: "0rem" }}
                label="Website Address"
                name="organization_webAddress"
                rules={[
                  {
                    required: true,
                    message:
                      "Please enter the organization website url in the exact format shown above.",
                  },
                ]}
              >
                <Input
                  showCount
                  maxLength={50}
                  placeholder="Organization Website"
                  size="large"
                />
              </Item>
            </Space>
            <Divider />

            <Space
              direction="vertical"
              size="small"
              className="profile_input_wrapper"
            >
              <Item
                style={{ marginBottom: "0rem" }}
                label="Additional Domains"
                name="organization_webAddress"
                extra={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: "1" }}>
                      <p
                        style={{
                          marginBottom: "0.5rem",
                          fontSize: "14px",
                          color: "#999",
                        }}
                      >
                        e.g. ( <a href="#">https://samplewebsite.com</a> )
                      </p>
                      <Alert
                        style={{ padding: "0.5rem", marginBottom: "0.5rem" }}
                        message={
                          <div>
                            <span>Please enter the website addresses</span>{" "}
                            <strong>
                              exactly as it appears on your websites
                            </strong>
                            .{" "}
                            <span>
                              This is important for the scheduler to function
                              correctly.
                            </span>
                          </div>
                        }
                        type="warning"
                        showIcon
                      />
                    </div>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message:
                      "Please enter the organization website url in the exact format shown above.",
                  },
                ]}
              >
                <Input
                  showCount
                  maxLength={50}
                  value={inputOrganizationDomain}
                  onChange={(e) => setInputOrganizationDomain(e.target.value)}
                  size="large"
                />
                <Button
                  style={{ marginTop: 10 }}
                  onClick={() =>
                    dispatch(
                      updateOrganizationDomains({
                        id: organizationData._id,
                        domain: inputOrganizationDomain,
                      })
                    )
                  }
                >
                  Add
                </Button>
                <List
                  size="small"
                  bordered
                  dataSource={organizationDomain}
                  className="profile_input_wrapper"
                  style={{
                    maxHeight: 300,
                    overflow: "auto",
                    margin: "20px 0px",
                    width: "100%",
                  }}
                  renderItem={(item) => (
                    <List.Item key={item}>
                      <List.Item.Meta title={item} />
                      <DeleteFilled
                        style={{ color: "#ff4d4f" }}
                        height="1em"
                        onClick={() => {
                          const newOrganizationDomain =
                            organizationDomain.filter(
                              (domain) => domain !== item
                            );
                          dispatch(
                            updateOrganization({
                              id: organizationData._id,
                              payload: {
                                ...organizationData,
                                organization_domains: newOrganizationDomain,
                              },
                            })
                          );
                        }}
                      />
                    </List.Item>
                  )}
                />
              </Item>
            </Space>

            <Divider />

            <Item
              label="Operational State"
              name="organization_state"
              className="profile_input_wrapper"
              rules={[
                {
                  required: true,
                  message: "Please select the organization state",
                },
              ]}
            >
              <Select
                showSearch
                options={states.map((item) => ({
                  label: item.label,
                  value: item.value,
                }))}
                filterOption={filterOption}
                size="large"
              />
            </Item>

            <Item
              label="Operational City"
              name="organization_city"
              className="profile_input_wrapper"
              rules={[
                {
                  required: true,
                  message: "Please select the organization address",
                },
              ]}
            >
              <ReactGoogleAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                defaultValue={form.getFieldValue("organization_city")}
                onPlaceSelected={async (place) => {
                  try {
                    const { lat, lng } = await getlongLat(
                      place.formatted_address
                    );
                    const timeZone = await getTimeZone(lat, lng);
                    setTimeZone(timeZone);
                  } catch (err) {
                    console.log(err);
                  }
                  form.setFieldValue(
                    `organization_city`,
                    place.formatted_address
                  );
                }}
                className="address-autofill"
                options={{
                  componentRestrictions: { country: "us" },
                  fields: ["address_components", "formatted_address"],
                  strictBounds: false,
                  types: ["locality"],
                }}
              />
            </Item>

            <Item
              label="Time Zone"
              className="profile_input_wrapper"
              extra={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "0.3rem",
                  }}
                >
                  <div style={{ flex: "1" }}>
                    <Alert
                      style={{ padding: "0.5rem" }}
                      message={
                        <div>
                          <span>
                            Only modify the <strong>time zone</strong> if you
                            intentionally follow a different{" "}
                            <strong>time zone</strong> otherwise, it will be
                            calculated for you when the{" "}
                            <strong>operational city</strong> is selected.
                          </span>
                        </div>
                      }
                      type="warning"
                      showIcon
                    />
                  </div>
                </div>
              }
            >
              <Select
                showSearch
                options={TimeZones}
                defaultValue={timeZone}
                value={timeZone}
                onSelect={(value) => setTimeZone(value)}
                size="large"
              />
            </Item>

            <Item
              label="Upload Logo"
              name="organization_logo"
              valuePropName="fileList"
              extra={
                <div>
                  <div>
                    Files must be less than: <strong>100KB</strong>
                  </div>
                  <div>
                    Allowed file types: <strong>jpg, png, jpeg</strong>
                  </div>
                  <div>
                    Preferred dimensions:{" "}
                    <strong>
                      {MAX_FILE_WIDTH} x {MAX_FILE_HEIGHT}
                    </strong>
                  </div>
                </div>
              }
            >
              <Upload
                action="https://file.io"
                listType="picture-card"
                fileList={fileList}
                beforeUpload={beforeUpload}
                onPreview={handlePreview}
                onChange={handleChange}
                accept=".png,.jpg,.jpeg"
                maxCount={1}
              >
                <div>
                  <PlusOutlined />
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    Upload
                  </div>
                </div>
              </Upload>
              <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="organization_logo"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  src={previewImage}
                />
              </Modal>
            </Item>

            <Divider />

            <Item
              name="isSTCapacitySet"
              label="Is ServiceTitan Capacity Configured?"
              valuePropName="checked"
            >
              <Switch
                style={{ width: 35, marginRight: 20 }}
                value={organizationData?.isSTCapacitySet}
              />
            </Item>

            {!organizationData?.isSTCapacitySet ? (
              <Item
                name="isCapacityUnthrottled"
                label="Do you want to trigger unthrottled capacity?"
                valuePropName="checked"
              >
                <Switch
                  style={{ width: 35, marginRight: 20 }}
                  value={organizationData?.isCapacityUnthrottled}
                />
              </Item>
            ) : null}

            <Divider />
            <Item
              name="prevent_noOwnership"
              label="Disallow Non-Home Owners From Booking Jobs"
              style={{ marginBottom: "0.2rem" }}
              className="profile_input_wrapper"
            >
              <Switch
                checked={preventNoOwnership}
                onChange={(value) => {
                  setPreventNoOwnership(value);
                }}
              />
            </Item>
            <Divider />
              <>
                <Item
                  name="thankyou_page"
                  label={<Typography>Custom Thank You Page</Typography>}
                  style={{ marginBottom: "0.2rem" }}
                  className="profile_input_wrapper"
                >
                  <Switch
                    checked={isThankYouPage.thankyou_page}
                    onChange={(value) => {
                      setIsThankYouPage({
                        ...isThankYouPage,
                        thankyou_page: value,
                      });
                    }}
                  />
                </Item>
                {isThankYouPage?.thankyou_page && (
                  <>
                    <Divider style={{ margin: "1rem 0" }} />
                    <Typography strong style={{ marginBottom: "0.5rem" }}>
                      Thank You Page URL
                    </Typography>
                    <Input
                      placeholder="Enter the Thank You page URL"
                      className="profile_input_wrapper"
                      style={{ width: "80%" }}
                      value={isThankYouPage.thankyou_page_url}
                      onChange={(e) => {
                        setIsThankYouPage({
                          thankyou_page: isThankYouPage?.thankyou_page,
                          thankyou_page_url: e.target.value,
                        });
                      }}
                      allowClear
                    />
                  </>
                )}
                <Divider />
              </>
            <Item
              label="Default Job Assigning Technician"
              name="job_assigning_technican_id"
              className="profile_input_wrapper"
            >
              <Select
                showSearch
                options={TechniciansList}
                filterOption={filterOption}
                size="large"
              />
            </Item>

            <Item
              label="Select Job Tags"
              extra="Sync job tags to ensure you have the latest updates from ServiceTitan, including any changes in tag status (activate or deactivate)."
            >
              {loading ? (
                <Spin />
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Select
                    showSearch
                    mode="multiple"
                    placeholder="Select job tags"
                    style={{ width: "100%", padding: "5px" }}
                    loading={loading}
                    options={
                      serviceTitanJobTags?.data
                        ? serviceTitanJobTags.data.map((item) => ({
                            label: item.name,
                            value: item.id,
                            desc: item.name,
                          }))
                        : []
                    }
                    filterOption={filterOption}
                    defaultValue={
                      serviceTitanJobTags?.data
                        ? serviceTitanJobTags.data
                            .filter((item) => tagIds?.includes(item.id))
                            .map((data) => {
                              return data.id;
                            }) ?? []
                        : []
                    }
                    value={
                      serviceTitanJobTags?.data
                        ? serviceTitanJobTags.data
                            .filter((item) => tagIds?.includes(item.id))
                            .map((data) => {
                              return data.id;
                            }) ?? []
                        : []
                    }
                    onChange={(value) => {
                      setTagIds([...value]);
                    }}
                    size="large"
                  ></Select>

                  <Button
                    style={{ width: "100%", padding: "5px", marginTop: 10 }}
                    title="Sync Job tags"
                    loading={loading}
                    onClick={syncJobTags}
                  >
                    Sync Job tags
                  </Button>
                </div>
              )}
            </Item>

            <Divider />

            <Item name="primaryColor" label="Primary Color">
              <Input type="color" defaultValue="#FF5959" />
            </Item>
          </Col>

          <Col span={12}>
            <Tooltip
              title="Use {{dispatch_fee}} to show dispatch fee in the header"
              placement="bottomLeft"
            >
              <Item
                label="Default Dispatch Header"
                name="default_dispatch_header"
              >
                <Input
                  showCount
                  maxLength={60}
                  placeholder="Dispatch Header"
                  size="large"
                />
              </Item>

              <Item label="Default Dispatch Body" name="default_dispatch_body">
                <TextArea
                  showCount
                  maxLength={150}
                  placeholder="Dispatch Body"
                  size="large"
                  autoSize={{
                    minRows: 1,
                    maxRows: 2,
                  }}
                />
              </Item>

              <Item
                label="Default Dispatch Checkbox"
                name="default_dispatch_text"
              >
                <Input
                  showCount
                  maxLength={25}
                  placeholder="Dispatch Checkbox"
                  size="large"
                />
              </Item>
            </Tooltip>

            <Divider />
            {exclusiveWeekendOrgs.includes(organizationData?._id) && (
              <Form.Item label="Enable Weekend Dispatch Fee">
                <Switch checked={showWeekend} onChange={handleWeekendToggle} />
              </Form.Item>
            )}
            {showWeekend && (
              <>
                <Tooltip
                  title="Use {{dispatch_fee}} to show weekend dispatch fee"
                  placement="bottomLeft"
                >
                  <Form.Item
                    label="Weekend Dispatch Header"
                    name="weekend_dispatch_header"
                  >
                    <Input
                      showCount
                      maxLength={60}
                      placeholder="Weekend Dispatch Header"
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Weekend Dispatch Body"
                    name="weekend_dispatch_body"
                  >
                    <TextArea
                      showCount
                      maxLength={150}
                      placeholder="Weekend Dispatch Body"
                      size="large"
                      autoSize={{ minRows: 1, maxRows: 2 }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Weekend Dispatch Checkbox"
                    name="weekend_dispatch_text"
                  >
                    <Input
                      showCount
                      maxLength={25}
                      placeholder="Weekend Dispatch Checkbox"
                      size="large"
                    />
                  </Form.Item>
                </Tooltip>
                
            <Divider />
              </>
            )}

            <Item
              name="zipCodes"
              label="Operational Zip Codes"
              className="profile_input_wrapper"
            >
              <Input
                value={currentZip}
                onChange={(e) => setCurrentZip(e.target.value)}
                placeholder="Zip Code"
                size="middle"
              />
              <Button style={{ marginTop: 10 }} onClick={handleAddZipCodes}>
                Add Zip Codes
              </Button>
            </Item>

            <List
              size="small"
              bordered
              dataSource={zipCodesList}
              className="profile_input_wrapper"
              style={{ maxHeight: 300, overflow: "auto", margin: "20px 0px" }}
              renderItem={(item) => (
                <List.Item key={item}>
                  <List.Item.Meta title={item} />
                  <DeleteFilled
                    style={{ color: "#ff4d4f" }}
                    height="1em"
                    onClick={() => onRemoveZip(item)}
                  />
                </List.Item>
              )}
            />

            <Item
              name="holidays"
              label="Block Dates"
              className="profile_input_wrapper"
            >
              <Flex vertical justify={"space-between"} gap={10}>
                <DatePicker
                  placeholder="Select Dates"
                  style={{ flex: 1, width: "100%", minHeight: "2.5rem" }}
                  format="DD-MM-YYYY"
                  multiple
                  sort
                  value={selectedDates}
                  onChange={(e) => {
                    const formattedDates = e.map(
                      (date) =>
                        `${date?.year}-${date?.monthIndex + 1}-${date?.day}`
                    );
                    setSelectedDates(e);
                    setHolidayList([...formattedDates]);
                  }}
                  plugins={[<DatePanel />]}
                />
                <Item
                  name="time_ranges"
                  style={{ marginBottom: "0.2rem" }}
                  label="Time Range"
                  className="profile_input_wrapper"
                >
                  <TimePicker.RangePicker
                    allowClear
                    disabled={blockAllday}
                    format="h:mm A"
                    value={selectedRange}
                    onChange={(timeRanges, timeStrings) => {
                      setBlockTimeRange(
                        `${timeStrings[0]} - ${timeStrings[1]}`
                      );
                      setselectedRange(timeStrings);
                    }}
                  />
                </Item>

                <Item
                  name="all_day"
                  label="Block all day"
                  style={{ marginBottom: "0.2rem" }}
                  className="profile_input_wrapper"
                >
                  <Switch
                    defaultChecked={true}
                    onChange={(value) => {
                      setBlockAllday(value);
                      setBlockTimeRange("");
                      setselectedRange([]);
                    }}
                  />
                </Item>

                <Button
                  style={{
                    maxWidth: "6rem",
                  }}
                  onClick={() => {
                    if (holidayList.length === 0) {
                      notification.error({
                        message: "Error",
                        description: "Please select dates",
                        duration: 3,
                      });
                      return;
                    }
                    if (blockAllday && blockTimeRange) {
                      notification.error({
                        message: "Error",
                        description:
                          "Select Block All day or Time. You can't select both",
                        duration: 3,
                      });
                      return;
                    } else if (!blockAllday && !blockTimeRange) {
                      notification.error({
                        message: "Error",
                        description:
                          "You have to give either Block all day or Time range for the selected blocked dates",
                        duration: 3,
                      });
                      return;
                    }
                    if (holidays.length === 0) {
                      const tranformedDates = holidayList?.map((date) => ({
                        date: date,
                        TimeRange: blockAllday ? "None" : blockTimeRange,
                        BlockAllday: blockTimeRange ? false : true,
                      }));
                      setHolidays([...tranformedDates]);
                    } else {
                      const uniqueDates = holidayList?.filter(
                        (item) =>
                          !holidays.some((holiday) => holiday.date === item)
                      );
                      const tranformedDates = uniqueDates.map((date) => ({
                        date: date,
                        TimeRange: blockAllday ? "None" : blockTimeRange,
                        BlockAllday: blockTimeRange ? false : true,
                      }));
                      setHolidays([...holidays, ...tranformedDates]);
                    }
                    setSelectedDates([]);
                    setselectedRange(null);
                    setHolidayList([]);
                    setBlockTimeRange(null);
                    notification.success({
                      message: "Success",
                      description: "Date successfully inserted",
                    });
                  }}
                >
                  Add Dates
                </Button>
              </Flex>
            </Item>
            <Table
              virtual
              scroll={{ y: "min-content" }}
              dataSource={holidays}
              columns={columns}
              pagination={false}
            />
          </Col>
        </Row>

        <Divider />

        <Button
          type="primary"
          size="large"
          htmlType="submit"
          disabled={form.getFieldValue("organization_name") === ""}
          style={{ width: "100%", marginTop: 20 }}
        >
          Save Settings
        </Button>
      </Form>
    </>
  );
};

export default OrganizationProfile;
