import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Spin,
  Typography,
  Upload,
} from "antd";

import {
  SettingOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import {
  updateOrganization,
  updateServiceTypeOfferings,
  updateTradeOfferings,
} from "../../redux/organizationSlice";
import { TradeIcons } from "../../utils/tradeIcons";
import { getBase64 } from "../../utils/constants";

const { Title, Text } = Typography;

const TradeServiceOfferings = () => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [tradeLogos, setTradeLogos] = useState([]);

  const { organizationData, loading } = useSelector(
    (state) => state?.organization
  );
  const { trades } = useSelector((state) => state.trade);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  useEffect(() => {
    if (organizationData?.trades_logo) {
      setTradeLogos(organizationData?.trades_logo || []);
    }
  }, [organizationData]);

  const onChangeTrade = (trade, value) => {
    dispatch(updateTradeOfferings({ trade, value }));
  };

  const onChangeServiceType = (trade, serviceType, value) => {
    dispatch(updateServiceTypeOfferings({ trade, serviceType, value }));
  };

  const handleSubmit = () => {
    const payload = {
      navigation_mapping: organizationData?.navigation_mapping,
      trade_logos: tradeLogos,
    };
    dispatch(updateOrganization({ id: organizationData._id, payload }));
  };

  const handleTradeLogosChange = async ({ fileList }, tradeData, tradeName) => {
    const updatedLogos = tradeLogos.filter(
      (item) => item.tradeId !== tradeData?._id
    );

    if (fileList.length > 0) {
      const newFiles = await Promise.all(
        fileList.map(async (file) => {
          const preview = await getBase64(file.originFileObj);
          file["preview"] = preview;
          return {
            tradeId: tradeData?._id,
            tradeName,
            name: tradeData?.tradeName,
            ...file,
          };
        })
      );
      updatedLogos.push(...newFiles);
    }

    setTradeLogos(updatedLogos);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleCancel = () => setPreviewOpen(false);
  return (
    <>
      <Spin spinning={loading} tip="Loading..." fullscreen />
      <div
        style={{ display: "flex", gap: 8, alignItems: "center" }}
        className="settings-header"
      >
        <SettingOutlined size={30} className="settings-icon" />
        <Title level={3} style={{ margin: 0 }}>
          Trades and Service Offerings
        </Title>
      </div>
      <br />
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        style={{
          backgroundColor: "#f9f9f9",
          padding: "20px",
          borderRadius: "8px",
        }}
        autoComplete="new-state"
      >
        <Row gutter={[24, 24]}>
          {organizationData?.navigation_mapping?.map((trade) => {
            const tradeData = trades.find(
              (item) => item.tradeName === trade?.trade?.name
            );
            const tradeUrl =
              tradeLogos?.find((Logo) => Logo.tradeId === tradeData?._id)
                ?.url ||
              tradeLogos?.find((Logo) => Logo.tradeId === tradeData?._id)
                ?.preview;
            const TradeIcon = tradeUrl ? (
              <div
                style={{ position: "relative", width: "60px"}}
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    borderRadius: "4px",
                  }}
                  src={tradeUrl}
                  alt={trade?.trade?.name}
                  className="trade-icon"
                />
                <EditOutlined
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "50%",
                    color: "#fff",
                    fontSize: "18px",
                    padding: "4px",
                    cursor: "pointer",
                  }}
                />
              </div>
            ) : TradeIcons[trade?.trade?.name] ? (
              TradeIcons[trade?.trade?.name]?.icon()
            ) : (
              <UploadOutlined />
            );

            return (
              <React.Fragment key={trade?.trade?.id}>
                <Col span={2}>
                  <Upload
                    action="https://file.io"
                    listType="picture-card"
                    fileList={tradeLogos?.filter(
                      (item) => item?.tradeId === tradeData?._id
                    )}
                    beforeUpload={() => false}
                    onPreview={handlePreview}
                    onChange={(e) =>
                      handleTradeLogosChange(e, tradeData, trade?.trade?.name)
                    }
                    maxCount={1}
                    style={{ textAlign: "center" }}
                    showUploadList={false}
                  >
                    {TradeIcon}
                  </Upload>
                  <Modal
                    open={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      alt="organization_logo"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      src={previewImage}
                    />
                  </Modal>
                </Col>
                <Col span={10}>
                  <div className="trade-offering">
                    <Checkbox
                      checked={trade?.isActive}
                      onChange={(e) =>
                        onChangeTrade(trade?.trade?.name, e.target.checked)
                      }
                      style={{ fontSize: "16px" }}
                    >
                      <Text strong style={{ fontSize: "18px" }}>
                        {trade?.trade?.name}
                      </Text>
                    </Checkbox>
                  </div>
                  <div
                    className="service-type-checkboxes"
                    style={{ marginTop: "10px" }}
                  >
                    {Object.keys(trade?.offeredServiceTypes)?.map(
                      (serviceType) => (
                        <Checkbox
                          key={serviceType}
                          checked={trade?.offeredServiceTypes[serviceType]}
                          onChange={(e) =>
                            onChangeServiceType(
                              trade?.trade?.name,
                              serviceType,
                              e.target.checked
                            )
                          }
                          disabled={!trade?.isActive}
                          style={{ marginRight: "8px" }}
                        >
                          <Text
                            style={{ fontSize: "14px", whiteSpace: "nowrap" }}
                          >
                            {serviceType.charAt(0).toUpperCase() +
                              serviceType.slice(1)}
                          </Text>
                        </Checkbox>
                      )
                    )}
                  </div>
                  <Divider style={{ margin: "12px 0" }} />
                </Col>
              </React.Fragment>
            );
          })}
        </Row>
        <Row justify="center" style={{ marginTop: "20px" }}>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{
              backgroundColor: "#1890ff",
              borderColor: "#1890ff",
              borderRadius: "5px",
              padding: "0 40px",
            }}
          >
            Save Changes
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default TradeServiceOfferings;
