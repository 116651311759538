import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Button,
  Card,
  Input,
  List,
  notification,
  Select,
  Spin,
  Typography,
} from "antd";
import { updateOrganization } from "../../../../redux/organizationSlice";

const { Option } = Select;

export default function ActiveTradesServices() {
  const { organizationData, loading } = useSelector(
    (state) => state.organization
  );
  const { trades: offeredTrades, services } = useSelector(
    (state) => state.trade
  );
  const dispatch = useDispatch();

  const [selectedTrade, setSelectedTrade] = useState(null);
  const [selectedServiceType, setSelectedServiceType] = useState(null);
  const [trades, setTrades] = useState([]);

  useEffect(() => {
    if (organizationData) {
      setTrades(
        organizationData.navigation_mapping.map((item) => ({
          id: item.trade.id,
          name: item.trade.name,
        }))
      );
      resetForm();
    }
  }, [organizationData]);

  const resetForm = () => {
    setSelectedServiceType(null);
    setSelectedTrade(null);
  };

  const handleTradeChange = (value) => {
    setSelectedTrade(value);
    setSelectedServiceType(null);
  };

  const activeTradeIds = organizationData?.navigation_mapping
    .filter((mapping) => mapping.isActive)
    .map((mapping) => {
      let trade = offeredTrades.find(
        (trade) => trade.tradeName === mapping.trade.name
      );
      return trade?._id;
    });



  const activeServiceIds = services
    ?.filter((service) => {
      const findIndex = organizationData?.navigation_mapping?.findIndex(
        (mapping) => mapping.trade.id === selectedTrade
      );


      if (findIndex !== -1) {
        const offeredServiceTypes = organizationData?.navigation_mapping[findIndex]?.offeredServiceTypes;
        const additionalServices = offeredServiceTypes?.additionalServices;
        return (
            Object.keys(offeredServiceTypes || {}).includes(service?.name.toLowerCase()) || 
            Object.keys(additionalServices || {}).includes(service?.name.toLowerCase())
        );
      }

      return false;
    })
    .map((service) => service?.id);

  const editActiveTrades = (value) => {
    const updatedOrganizationData = structuredClone(organizationData);
    let updatednavigationTrades = [];

    offeredTrades.forEach((offeredTrade) => {
      let existingTrade = updatedOrganizationData?.navigation_mapping?.find(
        (mapping) => mapping.trade.name === offeredTrade.tradeName
      );

      if (existingTrade) {
        existingTrade.isActive = value.includes(offeredTrade._id);
        updatednavigationTrades.push(existingTrade);
      } else {
        if (value.includes(offeredTrade._id)) {
          updatednavigationTrades.push({
            trade: {
              id:  organizationData?.navigation_mapping?.length + 1,
              name: offeredTrade.tradeName,
            },
            isActive: true,
            offeredServiceTypes: {
              repair: true,
              estimate: true,
              maintenance: true,
            },
            repair: [],
            estimate: [],
            maintenance: [],
          });
        }
      }
    });

    updatedOrganizationData.navigation_mapping = updatednavigationTrades;
    dispatch(
      updateOrganization({
        id: organizationData._id,
        payload: updatedOrganizationData,
        page: "capacity_operations"
      })
    );
  };

  const handleAddService= () => {
    let servicexist = services.find((service) => service.name.toLowerCase() === selectedServiceType.toLowerCase());
    if (!servicexist) {
        notification.error({
            message: "Service already exists",
            description: "Service does not exists in the list",
        });
        return;
    }
    const updatedOrganizationData = structuredClone(organizationData);
    const findIndex = updatedOrganizationData?.navigation_mapping?.findIndex(
        (mapping) => mapping.trade.id === selectedTrade
    );
    updatedOrganizationData["navigation_mapping"][findIndex]["offeredServiceTypes"]["additionalServices"] = {
        ...updatedOrganizationData["navigation_mapping"][findIndex]["offeredServiceTypes"]["additionalServices"],
        [selectedServiceType.toLowerCase()]: false
    }
    updatedOrganizationData["navigation_mapping"][findIndex]["additionalServices"]={
        ...updatedOrganizationData["navigation_mapping"][findIndex]["additionalServices"],
        [selectedServiceType.toLowerCase()]: []
    }
    dispatch(updateOrganization({ id: organizationData._id,payload: updatedOrganizationData,page: "capacity_operations"}))
}

  return (
    <>
      <Spin spinning={loading} fullscreen />
      <div style={{ padding: "24px", display: "flex", flexDirection: "column", gap: "24px",width:"100%"}}>
        <Card
          bordered={false}
          style={{
            flex: 1,
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            borderRadius: "10px",
          }}
          headStyle={{ textAlign: "center", color: "#555", fontWeight: "bold" }}
          title="Activate Trades"
        >
          <Select
            mode="multiple"
            placeholder="Select services"
            value={activeTradeIds}
            onChange={editActiveTrades}
            style={{ width: "100%" }}
          >
            {offeredTrades?.map((mapping) => (
              <Option key={mapping._id} value={mapping._id}>
                {mapping.tradeName}
              </Option>
            ))}
          </Select>
        </Card>
  
        <Card
          bordered={false}
          style={{
            flex: 1,
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            borderRadius: "10px",
          }}
          headStyle={{ textAlign: "center", color: "#555", fontWeight: "bold" }}
          title="Activate Services"
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Select
              placeholder="Select Trade"
              onChange={handleTradeChange}
              style={{ width: "100%" }}
              value={selectedTrade}
            >
              {trades.map((trade) => (
                <Option key={trade.id} value={trade.id}>
                  {trade.name}
                </Option>
              ))}
            </Select>
  
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Input
                value={selectedServiceType}
                onChange={(e) => setSelectedServiceType(e.target.value)}
                placeholder="Enter new service"
                style={{ flex: "1" }}
              />
              <Button type="primary" onClick={handleAddService}>
                Add
              </Button>
            </div>
  
            <Typography.Title level={4}>Active Services</Typography.Title>
            <List
              bordered
              dataSource={activeServiceIds}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </div>
        </Card>
      </div>
    </>
  );
  
}