import {
  Button,
  Divider,
  Flex,
  List,
  Select,
  Spin,
  Tag,
  Typography,
  Input,
  Row,
  Col,
  notification,
} from "antd";
import { FlagOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Campaigns } from "../../utils/campaigns";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { updateOrganization } from "../../redux/organizationSlice";
import { debounce } from "lodash";

const { Title, Text } = Typography;

const CreateCampaignMapping = () => {
  const dispatch = useDispatch();
  const {
    organizationData,
    serviceTitanCampaigns,
    loading,
    serviveTitanLoading,
  } = useSelector((state) => state?.organization);

  // Separate states for custom and standard campaigns
  const [customCampaigns, setCustomCampaigns] = useState([]);
  const [standardCampaigns, setStandardCampaigns] = useState([]);

  useEffect(() => {
    const custom =
      organizationData?.campaigns_mapping?.filter((c) => c.custom) || [];
    const standard =
      organizationData?.campaigns_mapping?.filter((c) => !c.custom) || [];
    setCustomCampaigns(custom);
    setStandardCampaigns(standard);
  }, [organizationData?.campaigns_mapping]);

  const debouncedAutoSave = useCallback(
    debounce((updatedCampaigns) => {
      const hasInvalidCampaign = updatedCampaigns.some(
        (campaign) =>
          (campaign.whatConverts_medium && !campaign.whatConverts_source) ||
          (!campaign.whatConverts_medium && campaign.whatConverts_source)
      );
  
      console.log(updatedCampaigns);

      if (hasInvalidCampaign) {
        notification.error({
          message: "If you select a medium, you must also select a source",
        });
        return;
      }
      if(organizationData?._id){
      dispatch(
        updateOrganization({
          id: organizationData?._id,
          payload: { campaigns_mapping: [...standardCampaigns, ...updatedCampaigns] },
          })
        );
      }
    }, 1000),
    [dispatch, organizationData?._id, standardCampaigns]
  );

  const handleChange = (medium, source, val) => {
    
    console.log(medium, source, val);
    const STCampaign = serviceTitanCampaigns?.find((e) => e.id === val);
    let campaigns = [...standardCampaigns];
    let campaignIndex = campaigns.findIndex(
      (e) =>
        e.whatConverts_medium === medium && e.whatConverts_source === source
    );
    const campaign = {
      whatConverts_medium: medium,
      whatConverts_source: source,
      st_campaign_id: val,
      st_campaign_name: STCampaign?.name || "",
    };
    if (campaignIndex === -1) {
      campaigns.push(campaign);
    } else {
      campaigns[campaignIndex] = { ...campaign };
    }
    setStandardCampaigns([...campaigns]);
    dispatch(
      updateOrganization({
        id: organizationData._id,
        payload: { campaigns_mapping: [...campaigns, ...customCampaigns] },
      })
    );
  };

  const handleCustomValue = (index, field, value) => {
    let updatedCustomCampaigns = structuredClone(customCampaigns);
    updatedCustomCampaigns[index][field] = value;
    setCustomCampaigns(updatedCustomCampaigns);
    debouncedAutoSave(updatedCustomCampaigns);
  };

  const handleCustomCampaignChange = (index, field, selectedCampaign) => {
    let updatedCustomCampaigns = structuredClone(customCampaigns);
    updatedCustomCampaigns[index] = {
      ...updatedCustomCampaigns[index],
      st_campaign_id: selectedCampaign?.value,
      st_campaign_name: selectedCampaign?.label,
    };
    setCustomCampaigns(updatedCustomCampaigns);
    debouncedAutoSave(updatedCustomCampaigns);
  };

  const addCustomCampaign = () => {
    setCustomCampaigns([
      ...customCampaigns,
      {
        whatConverts_medium: "",
        whatConverts_source: "",
        whatConverts_campaign: "",
        custom: true,
        st_campaign_id: "",
        st_campaign_name: "",
      },
    ]);
  };

  const removeCustomCampaign = (selectedCampaignIndex) => {
    setCustomCampaigns(
      customCampaigns.filter(
        (campaign, index) => index !== selectedCampaignIndex
      )
    );
  };

  const handleSubmit = () => {
    const combinedCampaigns = [...standardCampaigns, ...customCampaigns];
    const hasInvalidCampaign = combinedCampaigns.some(
      (campaign) =>
        (campaign.whatConverts_medium && !campaign.whatConverts_source) ||
        (!campaign.whatConverts_medium && campaign.whatConverts_source)
    );
    if (hasInvalidCampaign) {
      notification.error({
        message: "If you select a medium, you must also select a source",
      });
      return;
    }

    dispatch(
      updateOrganization({
        id: organizationData._id,
        payload: { campaigns_mapping: combinedCampaigns },
      })
    );
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const CampaignsList =
    serviceTitanCampaigns?.map((item) => ({
      value: item?.id,
      label: item?.name,
    })) || [];

  return (
    <>
      <Spin spinning={loading || serviveTitanLoading} fullscreen />
      <Flex align="center" gap={10} style={{ marginBottom: "50px" }}>
        <FlagOutlined style={{ fontSize: 20 }} />
        <Title level={3} style={{ margin: 0 }}>
          Campaign Mappings
        </Title>
      </Flex>

      <Flex align="center" justify="flex-end" style={{ margin: "25px 0px" }}>
        <Button type="primary" size="large" onClick={handleSubmit}>
          Save Created List
        </Button>
      </Flex>
      <Divider orientation="left" style={{ borderColor: "#337ab7" }}>
        <Title level={4} style={{ color: "#337ab7" }}>
          Default Campaigns
        </Title>
      </Divider>
      <List
        size="large"
        bordered
        dataSource={Campaigns}
        renderItem={(item) => (
          <>
            {item?.sources?.map((source) => {
              let selectedCampaign = standardCampaigns.find(
                (e) =>
                  e.whatConverts_medium === item.medium &&
                  e.whatConverts_source === source.source
              );
              if(source?.source==='other'){
                selectedCampaign = standardCampaigns.find(
                  (e) =>
                    e.whatConverts_medium === source.source
                );
              }
              return (
                <List.Item key={source.id}>
                  <Flex
                    style={{ width: "100%" }}
                    align="center"
                    justify="space-between"
                  >
                    <Flex style={{ width: "80%" }} align="center">
                      <Flex style={{ width: "100px" }} gap={20}>
                        <Tag color={item.colorCode} style={{ width: "100%" }}>
                          {item.trafficSource}
                        </Tag>
                      </Flex>
                      <Flex style={{ margin: "25px" }}>{source.logo}</Flex>

                      <Flex style={{ width: "150px" }} gap={20}>
                        <Title level={5} style={{ margin: "0px" }}>
                          {source.source}
                        </Title>
                      </Flex>
                      <Flex style={{ width: "50%" }}>
                        <Divider>
                          <Text type="secondary">maps to</Text>
                        </Divider>
                      </Flex>
                      <Flex style={{ width: "250px", marginLeft: "100px" }}>
                        <Select
                          style={{ width: 300 }}
                          allowClear
                          onChange={(e) =>
                            handleChange(source?.source==='other'?source?.source:item?.medium, source.source, e)
                          }
                          value={
                            selectedCampaign
                              ? selectedCampaign?.st_campaign_name
                              : ""
                          }
                          options={CampaignsList}
                          filterOption={filterOption}
                          showSearch
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </List.Item>
              );
            })}
          </>
        )}
      />

      {/* Custom Campaign Section */}
      <Divider orientation="left" style={{ borderColor: "#337ab7" }}>
        <Title level={4} style={{ color: "#337ab7" }}>
          Custom Campaigns
        </Title>
      </Divider>
      <List
        size="large"
        bordered
        dataSource={customCampaigns}
        renderItem={(item, index) => (
          <List.Item key={item._id}>
         <Row gutter={16} style={{ width: "100%", display: "flex", alignItems: "center" }}>
  <Col span={4}>
    <Input
      placeholder="Medium"
      value={item.whatConverts_medium}
      onChange={(e) =>
        handleCustomValue(
          index,
          "whatConverts_medium",
          e.target.value
        )
      }
    />
  </Col>
  <Col span={4}>
    <Input
      placeholder="Source"
      value={item.whatConverts_source}
      onChange={(e) =>
        handleCustomValue(
          index,
          "whatConverts_source",
          e.target.value
        )
      }
    />
  </Col>
  <Col span={6}>
    <Input
      placeholder="Campaign"
      value={item.whatConverts_campaign}
      onChange={(e) =>
        handleCustomValue(
          index,
          "whatConverts_campaign",
          e.target.value
        )
      }
    />
  </Col>
  <Col span={1}>
    <Divider type="vertical" style={{ height: "40px", margin: "0 10px" }} />
  </Col>
  <Col span={2}>
    <Text type="secondary" style={{ lineHeight: "40px" }}>
      maps to
    </Text>
  </Col>
  <Col span={5}>
    <Select
      placeholder="Map to Campaign"
      style={{ width: "100%" }}
      options={CampaignsList}
      value={item.st_campaign_name}
      onChange={(val) => {
        const selectedCampaign = CampaignsList.find(
          (opt) => opt.value === val
        );
        handleCustomCampaignChange(index, "st_campaign_id", selectedCampaign);
      }}
      filterOption={filterOption}
      showSearch
    />
  </Col>
  <Col span={2}>
    <Button
      type="text"
      icon={<DeleteOutlined />}
      onClick={() => removeCustomCampaign(index)}
    />
  </Col>
</Row>

          </List.Item>
        )}
      />

      <Row justify="center" style={{ marginTop: "20px" }}>
        <PlusOutlined
          style={{ fontSize: "24px", cursor: "pointer", color: "#1890ff" }}
          onClick={addCustomCampaign}
        />
      </Row>
    </>
  );
};

export default CreateCampaignMapping;
