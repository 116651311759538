import {
  SettingOutlined,
  FlagOutlined,
  // AreaChartOutlined,
  LockOutlined,
  ApartmentOutlined,
  PartitionOutlined,
  InsertRowLeftOutlined,
  UserAddOutlined,
  CloudServerOutlined,
  LinkOutlined,
  QuestionCircleOutlined,
  ArrowsAltOutlined,
  BarChartOutlined,
  DownSquareFilled,
  CalendarOutlined,
  WechatWorkOutlined,
  WechatOutlined,
} from "@ant-design/icons";
import AdminPage from "../pages/AdminPanel";
import Login from "../pages/Authentication/Login";
import Campaigns from "../pages/Campaigns";
import Jobs from "../pages/Jobs";
import Organization from "../pages/Organization";
import AdminJobOfferings from "../components/Admin/JobOfferings";
import AdminOrganizations from "../components/Admin/Organizations";
import CreateJobMapping from "../components/Jobs/createjobMapping";
import OrganizationUsers from "../components/Admin/OrganizationUsers";
import AdminTrades from "../components/Admin/Trades";
import OrgCapacity from "../components/Organization/OrgCapacity/index";
import NotificationAccounts from "../components/Organization/Notifications";
import Signup from "../pages/Authentication/SignUp";
import ServiceTitanBusinessUnits from "../components/Organization/ServiceTitanBusinessUnits";
import TradeServiceOfferings from "../components/Organization/TradeServiceOfferings";
import ForgotPassword from "../pages/Authentication/ForgetPassword";
import { BiAnalyse } from "react-icons/bi";
import Questions from "../components/Organization/Questions";
import Analytics from "../pages/Analytics";
import NavigationsFlow from "../components/Organization/NavigationsFlow";
import GenerateCustomURL from "../pages/CustomUrl";
import FollowUpLeadsHome from "../pages/FollowLeads";
import FollowUpLeads from "../components/Organization/FollowUpLeads";
import AdminServices from "../components/Admin/Services";
import CreateCampaignMapping from "../components/Campaigns/createCampaignMapping";
import CapacityPlanning from "../pages/CapacityPlanning";
import MarketingSettings from "../components/Campaigns/marketingSettings";
import JobSettings from "../components/Jobs/jobSettings";
import CompanyProfile from "../pages/CompanyProfile";
import ServiceArea from "../pages/ServiceArea";
import BlockDates from "../components/CapacityPlanning/BlockDates";
import CapacitySettings from "../components/CapacityPlanning/CapacitySettings";
import MapLogoIcon from "../components/UI/maplogoIcon";
import WrenchLogoIcon from "../components/UI/wrenchlogoIcon";
import NotificationLogoIcon from "../components/UI/notification";
import DetailedAnalysis from "../components/Analytics/DetailedAnalysis";
import Overview from "../components/Analytics/Overview";
import Adminanalytics from "../components/Admin/Adminanalytics";


const AuthenticatedRoutes = [
  {
    name: "Jobs",
    routePath: "/jobMappings",
    icon: <ApartmentOutlined />,
    isAdminRestricted: false,
    component: <Jobs />,
  },
  {
    name: "Marketing",
    routePath: "/campaignsMapping",
    icon: <FlagOutlined />,
    isAdminRestricted: false,
    component: <Campaigns />,
  },
  {
    name: "Settings",
    routePath: "/orgSettings",
    icon: <SettingOutlined />,
    isAdminRestricted: false,
    component: <Organization />,
  },
  {
    name: "Analytics",
    routePath: "/analytics",
    icon: <BiAnalyse />,
    isAdminRestricted: false,
    component: <Analytics />,
  },
  {
    name: "Follow Ups",
    routePath: "/followupleads",
    icon: <BiAnalyse />,
    isAdminRestricted: false,
    component: <FollowUpLeadsHome />,
  },
  {
    name: "Admin",
    routePath: "/adminSettings",
    icon: <LockOutlined />,
    isAdminRestricted: true,
    component: <AdminPage />,
  },
  {
    name:"Capacity Planning",
    routePath:"/capacityPlanning",
    icon: <CalendarOutlined />,
    isAdminRestricted:false,
    component:<CapacityPlanning/>
  }
];

const PublicRoutes = [
  {
    name: "Login",
    routePath: "/",
    icon: "",
    isAdminRestricted: false,
    component: <Login />,
  },
  {
    name: "Signup",
    routePath: "/verify/:token/:type",
    icon: "",
    isAdminRestricted: false,
    component: <Signup />,
  },
  {
    name: "ForgotPassword",
    routePath: "/ForgotPassword",
    isAdminRestricted: false,
    component: <ForgotPassword />,
  },
  {
    name: "About",
    routePath: "/about",
    icon: "",
    isAdminRestricted: false,
    component: <Login />,
  },
];

export const MarketingTabs = [
  {
    name: "Campaign Mapping",
    icon: <FlagOutlined />,
    component: <CreateCampaignMapping />,
  },
  {
    name:'Quick Booking Links',
    icon:<LinkOutlined/>,
    component:<GenerateCustomURL/>
  },
  {
    name:"Marketing Settings",
    icon:<SettingOutlined/>,
    component:<MarketingSettings/>
  },
]

export const CapacityTabs = [
  {
    name: "Block Dates",
    icon: <CalendarOutlined />,
    component: <BlockDates />,
  },
  {
    name: "Capacity Management",
    icon: <CalendarOutlined />,
    component: <OrgCapacity />,
  },
  {
    name: "Capacity Settings",
    icon: <SettingOutlined />,
    component: <CapacitySettings />,
  },

]

export const AnalyticsTabs = [
  {
    name:"Overview",
    icon:<BiAnalyse/>,
    component: <Overview/>,
  },
  {
    name: "Contacts",
    icon: <BiAnalyse />,
    component: <DetailedAnalysis />,
  },
];


const AdminTabs = [
  {
    name: "Trades",
    icon: <PartitionOutlined />,
    component: <AdminTrades />,
  },
  {
    name: "Service Types",
    icon: <PartitionOutlined />,
    component: <AdminServices />,
  },
  {
    name:"Analytics",
    icon:<BarChartOutlined />,
    component:<Adminanalytics/>

  },
  {
    name: "Job Types",
    icon: <PartitionOutlined />,
    component: <AdminJobOfferings />,
  },

  {
    name: "Organization",
    icon: <SettingOutlined />,
    component: <AdminOrganizations />,
  },
  {
    name: "Organization Users",
    icon: <UserAddOutlined />,
    component: <OrganizationUsers />,
  },
  {
    name: "Organization Associated Questions",
    icon: <QuestionCircleOutlined />,
    component: <Questions />,
  },
  {
    name: "Organization Navigation Flows",
    icon: <ArrowsAltOutlined />,
    component: <NavigationsFlow />,
  },
];

export const JobTabs = [
  {
    name: "Job Mapping",
    icon: <InsertRowLeftOutlined />,
    component: <CreateJobMapping />,
  },
  {
    name: "Job Settings",
    icon: <SettingOutlined />,
    component:<JobSettings/>
  }
  ,
];

export const FollowUpLeadsTabs = [
  {
    name: "New Leads",
    icon: <BarChartOutlined />,
    value: "New Lead",
    component: <FollowUpLeads key={"new lead"} followuptype="New Lead" />,
  },
  {
    name: "Archived Leads",
    icon: <DownSquareFilled />,
    value: "Archived Lead",
    component: (
      <FollowUpLeads key={"archived lead"} followuptype="Archived Lead" />
    ),
  },
];

export const OrganizationTabs = [
  {
    name: "Company Profile",
    icon: <InsertRowLeftOutlined />,
    component: <CompanyProfile/>,
    isCapacityDependent: false,
  },
  {
    name: "Service Area",
    icon: <MapLogoIcon/>,
    component: <ServiceArea/>,
  },
  {
    name: "Trades & Service Offerings",
    icon:<WrenchLogoIcon/>,
    component: <TradeServiceOfferings />,
    isCapacityDependent: false,
  },
  {
    name: "Notification Accounts",
    icon:<NotificationLogoIcon/>,
    component: <NotificationAccounts />,
    isCapacityDependent: false,
  },
  {
    name: "ServiceTitan Info",
    icon: <CloudServerOutlined size={30} />,
    component: <ServiceTitanBusinessUnits />,
    isCapacityDependent: false,
  },
];

export const emailType = {
  Reset: "reset",
  Verification: "verification",
};
export { PublicRoutes, AuthenticatedRoutes, AdminTabs };

export const MAX_FILE_WIDTH = 350;
export const MAX_FILE_HEIGHT = 150;
export const MAX_ALLOWED_QUESTIONS = 5;
export const MAX_Leads = 2500;

export const Exclusive_Features = [
  "https://texomateam.com",
  "https://www.cardinalplumbingva.com",
];

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const exclusiveCampaignOrgs=[
    '668f8312e8fff902d6dc7e6f',
    '66225b878d91148be33bcf9d',
    '6602e66988727bd96993d2d8',
    '65b39d2755dc3a592bdadfbe'
  ]

  export const exclusiveRedirectOrgs=[
    '668f8312e8fff902d6dc7e6f',
    '66225b878d91148be33bcf9d',
    '6602e66988727bd96993d2d8',
    '65b39d2755dc3a592bdadfbe'
  ]

export const exclusiveWeekendOrgs=[
  '668f8312e8fff902d6dc7e6f',
  '661ed5138d91148be308504d'
]

export const HvacRepair = () => (
	<svg
			width="40"
		height="40"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1763_342)">
			<path
				d="M21.3816 12.3273C21.3816 12.5091 21.2362 12.6545 21.0907 12.6909L19.2725 13.0182C19.1635 13.3818 19.0544 13.7091 18.9089 14.0727C18.8725 14.1818 18.7635 14.2545 18.6544 14.2909C18.5453 14.3273 18.3998 14.2909 18.3271 14.1818L16.6907 12.5454C16.5816 12.4727 16.5453 12.3273 16.5816 12.2182C17.5271 8.39999 14.618 4.76362 10.7635 4.76362C5.49073 4.76362 2.76346 11.2 6.54528 14.9818C8.03619 16.4727 10.1816 17.0545 12.218 16.5454C12.3271 16.5091 12.4725 16.5454 12.5816 16.6545L14.218 18.2909C14.2907 18.3636 14.3271 18.5091 14.3271 18.6182C14.2907 18.7273 14.218 18.8364 14.1089 18.8727C13.7816 19.0182 13.4544 19.1273 13.1271 19.2L12.7998 21.0182C12.7635 21.2 12.618 21.3091 12.4362 21.3091H9.23619C9.05437 21.3091 8.90891 21.2 8.87255 21.0182L8.54528 19.2C7.78164 19.0182 7.09073 18.7273 6.43619 18.3273L4.90891 19.3818C4.76346 19.4909 4.58164 19.4545 4.43619 19.3454L2.18164 17.1636C2.07255 17.0545 2.03619 16.8364 2.14528 16.6909L3.19982 15.1636C2.83619 14.5454 2.50891 13.8182 2.3271 13.0909L0.47255 12.7636C0.290732 12.7273 0.181641 12.5818 0.181641 12.4V9.19999C0.181641 9.01817 0.327095 8.87272 0.47255 8.83635L2.29073 8.50908C2.47255 7.78181 2.76346 7.05453 3.16346 6.39999L2.10891 4.87272C1.99982 4.72726 2.03619 4.54544 2.14528 4.39999L4.39982 2.18181C4.50891 2.07272 4.72709 2.03635 4.87255 2.14544L6.39982 3.19999C7.05437 2.83635 7.74528 2.50908 8.47255 2.32726L8.79982 0.509079C8.83619 0.327261 8.98164 0.21817 9.16346 0.21817H12.3635C12.5453 0.21817 12.6907 0.363625 12.7271 0.509079L13.0544 2.32726C13.7816 2.50908 14.5089 2.79999 15.1635 3.19999L16.6907 2.14544C16.8362 2.03635 17.018 2.07272 17.1635 2.18181L19.418 4.43635C19.5635 4.54544 19.5635 4.76362 19.4544 4.90908L18.3998 6.43635C18.7635 7.0909 19.0907 7.78181 19.2725 8.54544L21.0907 8.87272C21.2725 8.90908 21.3816 9.05453 21.3816 9.23635V12.3273Z"
				fill="#007BFF"
			/>
			<path
				d="M20.6542 17.6363C19.0906 17.8182 17.7815 19.0545 17.5997 20.6909L12.5815 15.6C12.1815 15.2 11.636 14.9818 11.1269 15.0182C8.10876 15.2727 5.74512 12.2909 6.79967 9.30907C6.90876 9.05452 7.19967 8.98179 7.38149 9.16361L9.59967 11.3818C9.78149 11.5636 10.036 11.5636 10.2179 11.3818L11.4542 10.1454C11.636 9.96361 11.636 9.70907 11.4542 9.52725L9.23603 7.30907C9.05421 7.12725 9.12694 6.79998 9.38149 6.72725C12.2179 5.70907 15.2724 7.99998 15.0178 11.0909C14.9815 11.6363 15.1997 12.1454 15.5997 12.5454L20.6542 17.6363Z"
				fill="#007BFF"
			/>
			<path
				d="M23.8178 21.0545C23.8178 19.5273 22.5814 18.3273 21.0542 18.2909C19.5269 18.2909 18.3269 19.5273 18.2905 21.0545C18.2905 22.5818 19.5269 23.8182 21.0542 23.8182C22.5814 23.8182 23.8178 22.5818 23.8178 21.0545ZM21.0905 22.0727C20.5451 22.0727 20.0723 21.6363 20.0723 21.0545C20.0723 20.5091 20.5087 20.0363 21.0905 20.0363C21.636 20.0363 22.0723 20.4727 22.1087 21.0545C22.0723 21.6363 21.636 22.0727 21.0905 22.0727Z"
				fill="#007BFF"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1763_342">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export const Maintenance = () => (
	<svg
			width="40"
		height="40"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1763_83)">
			<path
				d="M14.7516 12.3514L10.8 16.303L9.24842 14.7514C9.137 14.64 9.00473 14.5517 8.85916 14.4913C8.7136 14.4311 8.55758 14.4 8.40002 14.4C8.24246 14.4 8.08643 14.4311 7.94086 14.4913C7.7953 14.5517 7.66302 14.64 7.55162 14.7514C7.44021 14.8628 7.35183 14.9951 7.29153 15.1406C7.23123 15.2862 7.2002 15.4422 7.2002 15.5998C7.2002 15.7574 7.23123 15.9134 7.29153 16.059C7.35183 16.2046 7.44021 16.3368 7.55162 16.4483L9.95161 18.8483C10.0628 18.96 10.195 19.0488 10.3406 19.1093C10.4862 19.1699 10.6423 19.2011 10.8 19.2011C10.9577 19.2011 11.1138 19.1699 11.2594 19.1093C11.405 19.0488 11.5372 18.96 11.6484 18.8483L16.4485 14.0482C16.5598 13.9368 16.6482 13.8046 16.7085 13.659C16.7688 13.5134 16.7998 13.3574 16.7998 13.1998C16.7998 13.0422 16.7688 12.8862 16.7085 12.7406C16.6482 12.5951 16.5598 12.4628 16.4485 12.3514C16.337 12.24 16.2048 12.1517 16.0592 12.0913C15.9136 12.0311 15.7576 12 15.6 12C15.4424 12 15.2864 12.0311 15.1408 12.0913C14.9953 12.1517 14.863 12.24 14.7516 12.3514Z"
				fill="#007BFF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.0002 2.4H21.0002C21.9602 2.4 22.8002 3.24 22.8002 4.2V22.2C22.8002 23.16 21.9602 24 21.0002 24H3.0002C2.0402 24 1.2002 23.16 1.2002 22.2V4.2C1.2002 3.24 2.0402 2.4 3.0002 2.4H4.8002V1.2C4.8002 0.537258 5.33746 0 6.0002 0C6.66293 0 7.20019 0.537258 7.20019 1.2V2.4H15.6002V1.2C15.6002 0.537258 16.1374 0 16.8002 0C17.463 0 18.0002 0.537258 18.0002 1.2V2.4ZM20.4002 21.6H3.6002V9.6H20.4002V21.6Z"
				fill="#007BFF"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1763_83">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export const PlumbingInstallation = () => (
	<svg
			width="40"
		height="40"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_1763_2331)">
			<path
				d="M0 14.0652V19.6902C0 20.0786 0.314758 20.3934 0.703125 20.3934H5.29907V13.3621H0.703125C0.314758 13.3621 0 13.6769 0 14.0652Z"
				fill="#007BFF"
			/>
			<path
				d="M14.8126 12.6658V11.4402C14.8126 11.4029 14.8088 11.3666 14.8033 11.3309H9.19696C9.19128 11.3666 9.18762 11.4031 9.18762 11.4402V12.6667L8.1712 13.3621H6.70508V20.3934H8.26733C8.56012 20.3934 8.80072 20.441 9.07172 20.5529C9.24622 20.6248 9.43811 20.7048 9.63531 20.7872C10.2604 21.048 10.9068 21.3179 11.2867 21.451C11.5235 21.5339 11.7617 21.5753 12.0001 21.5753C12.2383 21.5753 12.4766 21.5339 12.7131 21.451C13.1989 21.2807 14.2192 20.8638 14.945 20.5585C15.2202 20.4428 15.4648 20.3934 15.7631 20.3934H17.2988V13.3621H15.829L14.8126 12.6658Z"
				fill="#007BFF"
			/>
			<path
				d="M23.2968 13.3621H18.7051V20.3934H23.2968C23.6852 20.3934 23.9999 20.0786 23.9999 19.6902V14.0652C23.9999 13.6769 23.6852 13.3621 23.2968 13.3621Z"
				fill="#007BFF"
			/>
			<path
				d="M17.1562 2.4375H6.84375C6.06702 2.4375 5.4375 3.0672 5.4375 3.84375C5.4375 4.6203 6.06702 5.25 6.84375 5.25H17.1562C17.933 5.25 18.5625 4.62048 18.5625 3.84375C18.5625 3.0672 17.933 2.4375 17.1562 2.4375Z"
				fill="#007BFF"
			/>
			<path
				d="M10.582 6.04688H13.3945V10.5469H10.582V6.04688Z"
				fill="#007BFF"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1763_2331">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);


export const icons = {
 	// service types
	repair: HvacRepair,
	estimate: PlumbingInstallation,
	maintenance: Maintenance,
}