import { Card, Row, Col, Switch, Typography } from "antd";
import { SingleSelect } from "../General/Select";
import { useSelector } from "react-redux";
import { memo } from "react";

const { Text, Title } = Typography;

const JobMapping = ({
  jobId,
  mappedData,
  filterOption,
  onChange,
}) => {
  const { serviceTitanBusinessUnits, serviceTitanJobs } = useSelector(
    (state) => state?.organization
  );

  return (
    <Card
      title={<Title level={5}>Job Mapping</Title>}
      style={{
		width: "100%"
      }}
      bodyStyle={{ padding: "20px" }}
    >
      <Row gutter={[20, 20]} align="middle">
        {/* Business Unit Selector */}
        <Col xs={24} md={12} lg={10}>
          <SingleSelect
            title="Business Unit"
            showSearch
            placeholder="Select Business Unit"
            value={mappedData?.st_business_unit_id}
            onChange={(e) => {
              const selectedST = serviceTitanBusinessUnits.find(
                (val) => val.id === e
              );
              onChange(
                {
                  st_business_unit_id: e,
                  st_business_unit_name: selectedST?.name || "",
                },
                jobId
              );
            }}
            filterOption={filterOption}
            options={serviceTitanBusinessUnits?.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            size="middle"
            style={{ width: "100%" }}
          />
        </Col>

        {/* Is Member Switch */}
        <Col xs={24} md={12} lg={4}>
          <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Text type="secondary" strong>
              Is Member
            </Text>
            <Switch
              style={{maxWidth:'50px'}}
              checked={mappedData?.is_member}
              onChange={(e) => onChange({ is_member: e }, jobId)}
            />
          </div>
        </Col>

        {/* Non-Member Job Type Selector */}
        <Col xs={24} md={12} lg={10}>
          <SingleSelect
            title="Non-Member Job Type"
            showSearch
            placeholder="Select Non-Member Job Type"
            value={mappedData?.st_non_member_job_id}
            onChange={(e) => {
              const selectedJobItem = serviceTitanJobs.find((val) => val.id === e);
              onChange(
                {
                  st_non_member_job_id: e,
                  st_non_member_job_name: selectedJobItem?.name || "",
                },
                jobId
              );
            }}
            filterOption={filterOption}
            options={serviceTitanJobs?.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            size="middle"
            style={{ width: "100%" }}
          />
        </Col>

        {/* Member Job Type Selector */}
        {mappedData?.is_member && (
          <Col xs={24} md={12} lg={8}>
            <SingleSelect
              title="Member Job Type"
              showSearch
              placeholder="Select Member Job Type"
              value={mappedData?.st_member_job_id}
              onChange={(e) => {
                const selectedJobItem = serviceTitanJobs.find(
                  (val) => val.id === e
                );
                onChange(
                  {
                    st_member_job_id: e,
                    st_member_job_name: selectedJobItem?.name || "",
                  },
                  jobId
                );
              }}
              filterOption={filterOption}
              options={serviceTitanJobs?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              size="middle"
              style={{ width: "100%" }}
            />
          </Col>
        )}
      </Row>
    </Card>
  );
};


export default memo(JobMapping);
