import React, { useEffect, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./editor.css";

import { registerSmartBreak } from "./registerSmartBreak";

const Parchment = Quill.import("parchment");

registerSmartBreak();

function lineBreakMatcher() {
  var Delta = Quill.import("delta");
  var newDelta = new Delta();
  newDelta.insert({ break: "" });
  return newDelta;
}

function handleLinebreak(range, context) {
  const { quill } = this;
  const currentLeaf = quill.getLeaf(range.index)[0];
  const nextLeaf = quill.getLeaf(range.index + 1)[0];

  quill.insertEmbed(range.index, "break", true, Quill.sources.USER);

  if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
    quill.insertEmbed(range.index, "break", true, Quill.sources.USER);
  }
  quill.setSelection(range.index + 1, Quill.sources.SILENT);

  Object.keys(context.format).forEach((name) => {
    if (Parchment.query(name, Parchment.Scope.BLOCK)) return;
    if (Array.isArray(context.format[name])) return;
    if (name === "link") return;
    quill.format(name, context.format[name], Quill.sources.USER);
  });
}

function handleEnter(range, context) {
  const { quill } = this;

  if (range.length > 0) {
    quill.scroll.deleteAt(range.index, range.length);
  }
  const lineFormats = Object.keys(context.format).reduce((acc, format) => {
    if (
      Parchment.query(format, Parchment.Scope.BLOCK) &&
      !Array.isArray(context.format[format])
    ) {
      acc[format] = context.format[format];
    }
    return acc;
  }, {});

  const previousChar = quill.getText(range.index - 1, 1);

  quill.insertText(range.index, "\n", lineFormats, Quill.sources.USER);

  if (
    previousChar == "" ||
    (previousChar == "\n" &&
      !(context.offset > 0 && context.prefix.length === 0))
  ) {
    quill.setSelection(range.index + 2, Quill.sources.SILENT);
  } else {
    quill.setSelection(range.index + 1, Quill.sources.SILENT);
  }
  Object.keys(context.format).forEach((name) => {
    if (lineFormats[name] != null) return;
    if (Array.isArray(context.format[name])) return;
    if (name === "link") return;
    quill.format(name, context.format[name], Quill.sources.USER);
  });
}

const Editor = ({ onChange, value }) => {
  const quillRef = useRef(null);

  const removeExtraneousLines = () => {
    const quill = quillRef.current.getEditor();
    const length = quill.getLength();
    const text = quill.getText(length - 2, 2);

    if (text === "\n\n") {
      quill.deleteText(quill.getLength() - 2, 2);
    }
  };

  useEffect(() => {
    removeExtraneousLines();
  }, []);

  return (
    <div className="quill-editor-wrapper">
      <ReactQuill
        ref={quillRef}
        className="editor custom-quill"
        theme="snow"
        modules={{
          toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['link', 'image'],
            ['clean']
          ],
          clipboard: {
            matchers: [["BR", lineBreakMatcher]],
            matchVisual: false
          },
          keyboard: {
            bindings: {
              break: {
                key: 13,
                handler: handleEnter
              },
              linebreak: {
                key: 13,
                shiftKey: true,
                handler: handleLinebreak
              }
            }
          }
        }}
        {...{ onChange, value }}
      />
    </div>
  );
};

export default Editor;
