import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Flex, Typography } from "antd";
import { CloudServerOutlined } from "@ant-design/icons";
import { styles } from "./FileUploadingConstants";
import {
	useCSVReader,
	lightenDarkenColor,
	formatFileSize,
} from "react-papaparse";
import { updateOrganization } from "../../../redux/organizationSlice";

const { Title } = Typography;
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
	DEFAULT_REMOVE_HOVER_COLOR,
	40
);
const Default_Services=['repair','maintenance','estimate'];
const JobsMappingUpload = () => {
	const dispatch = useDispatch();
	const {
		organizationData,
		serviceTitanBusinessUnits,
		serviceTitanJobs,
		loading,
	} = useSelector((state) => state?.organization);
	const { trades } = useSelector((state) => state?.trade);
	const { jobs } = useSelector((state) => state?.jobs);

	const { CSVReader } = useCSVReader();
	const [zoneHover, setZoneHover] = useState(false);
	const [uploadedMappings, setUploadedMappings] = useState(null);
	const [removeHoverColor, setRemoveHoverColor] = useState(
		DEFAULT_REMOVE_HOVER_COLOR
	);

	const handleMappingsUpload = (results) => {
		const mappingsStructure = JSON.parse(
			JSON.stringify(organizationData?.jobs_mapping)
		);
		const {
			default_dispatch_header,
			default_dispatch_body,
			default_dispatch_text,
		} = organizationData;

		results?.data?.forEach((row, index) => {
			if (index > 0) {
				const trade = trades?.find((e) => e?.tradeName === row[0]);
				const serviceType = row[2].toLowerCase();
				const job = jobs?.find(
					(e) =>
						e?.jobItem === row[1] &&
						e?.serviceType === serviceType &&
						e?.trade?._id === trade?._id
				);
				const tradeIndex = mappingsStructure?.findIndex(
					(e) => e.trade === trade?._id
				);
				let JobFound =[]
				if(Default_Services.includes(serviceType)){
				 JobFound= mappingsStructure[tradeIndex][serviceType]?.find(
					(e) => e?.jobItem === job?._id
				);
			    }
				else{
				JobFound=mappingsStructure[tradeIndex]?.['additionalServices']?.[serviceType]?.find(
						(e) => e?.jobItem === job?._id
					);
				}

				if (JobFound) {
					const BU_Id = serviceTitanBusinessUnits?.find(
						(e) => e?.name === row[4]
					);
					const Non_Member_Job_Id = serviceTitanJobs?.find(
						(e) => e?.name === row[5]
					);
					const Member_Job_Id = serviceTitanJobs?.find(
						(e) => e?.name === row[6]
					);

					JobFound.is_active = row[3]?.toLowerCase() === "yes" ? true : false;
					JobFound.st_business_unit_id = BU_Id?.id || "";
					JobFound.st_business_unit_name = BU_Id?.name || "";

					JobFound.st_non_member_job_id = Non_Member_Job_Id?.id || "";
					JobFound.st_non_member_job_name = Non_Member_Job_Id?.name || "";

					JobFound.is_member = true;
					JobFound.st_member_job_id =
						Member_Job_Id?.id || Non_Member_Job_Id?.id || "";
					JobFound.st_member_job_name =
						Member_Job_Id?.name || Non_Member_Job_Id?.name || "";

					JobFound.dispatch_fee = row[7]?.replace("$", "") || 0;
					JobFound.member_dispatch_fee=row[8]?.replace("$","") || 0;
					
					if (
						default_dispatch_header.trim() === row[9].trim() &&
						default_dispatch_body.trim() === row[10].trim() &&
						default_dispatch_text.trim() === row[11].trim()
					) {
						JobFound.use_default_dispatch = true;
					} else {
						JobFound.use_default_dispatch = false;
						JobFound.dispatch_header = row[9];
						JobFound.dispatch_body = row[10];
						JobFound.dispatch_text = row[11];
					}
				}
				else {
					if (job && trade && serviceType) {
						const newJobMapping = {
							jobItem: job?._id,
							jobItemName: job?.jobItem,
							serviceType,
							is_active: row[3]?.toLowerCase() === "yes" ? true : false,
							st_business_unit_id: serviceTitanBusinessUnits?.find(
								(e) => e?.name === row[4]
							)?.id || "",
							st_business_unit_name: serviceTitanBusinessUnits?.find(
								(e) => e?.name === row[4]
							)?.name || "",
							st_non_member_job_id: serviceTitanJobs?.find(
								(e) => e?.name === row[5]
							)?.id || "",
							st_non_member_job_name: serviceTitanJobs?.find(
								(e) => e?.name === row[5]
							)?.name || "",
							is_member: true,
							st_member_job_id:
								serviceTitanJobs?.find((e) => e?.name === row[6])?.id ||
								"",
							st_member_job_name:
								serviceTitanJobs?.find((e) => e?.name === row[6])?.name ||
								"",
							dispatch_fee: row[7]?.replace("$", "") || 0,
							member_dispatch_fee: row[8]?.replace("$", "") || 0,
							use_default_dispatch:
								default_dispatch_header.trim() === row[9].trim() &&
								default_dispatch_body.trim() === row[10].trim() &&
								default_dispatch_text.trim() === row[11].trim()
									? true
									: false,
							dispatch_header: row[9],
							dispatch_body: row[10],
							dispatch_text: row[11],
						};
						const tradeIndex = mappingsStructure.findIndex(
							(e) => e.trade === trade?._id
						);
						if (tradeIndex !== -1) {
							if(Object.keys(mappingsStructure[tradeIndex]).includes(serviceType)){
							const serviceTypeIndex = mappingsStructure[tradeIndex][serviceType];
							if (serviceTypeIndex) {
								mappingsStructure[tradeIndex][serviceType].push(newJobMapping);
							} else {
								mappingsStructure[tradeIndex][serviceType] = [newJobMapping];
							}
						}
						else{
							if(mappingsStructure[tradeIndex]['additionalServices']){
								if(Object.keys(mappingsStructure[tradeIndex]['additionalServices']).includes(serviceType)){
									const serviceTypeIndex = mappingsStructure[tradeIndex]['additionalServices'][serviceType];
									if (serviceTypeIndex) {
										mappingsStructure[tradeIndex]['additionalServices'][serviceType].push(newJobMapping);
									} else {
										mappingsStructure[tradeIndex]['additionalServices'][serviceType] = [newJobMapping];
									}
								}
								else{
									mappingsStructure[tradeIndex]['additionalServices'][serviceType] = [newJobMapping];
								}
							}
							else{
								mappingsStructure[tradeIndex]['additionalServices']={};
								mappingsStructure[tradeIndex]['additionalServices'][serviceType] = [newJobMapping];
							}
						}
						}
					}
				}
			}
		});
		setUploadedMappings(mappingsStructure);
	};

	const handleMappings = () => {
		dispatch(
			updateOrganization({
				id: organizationData._id,
				payload: {
					jobs_mapping: uploadedMappings,
				},
				page: "Upload Job Mappings"
			})
		);
	};

	return (
		<>
			<Flex align="center" gap={10} style={{ marginBottom: "50px" }}>
				<CloudServerOutlined style={{ fontSize: 20 }} />
				<Title level={3} style={{ margin: 0 }}>
					Upload Job Mappings
				</Title>
			</Flex>

			<CSVReader
				onUploadAccepted={(results) => {
					setUploadedMappings(null);
					handleMappingsUpload(results);
					setZoneHover(false);
				}}
				onDragOver={(event) => {
					event.preventDefault();
					setZoneHover(true);
				}}
				onDragLeave={(event) => {
					event.preventDefault();
					setZoneHover(false);
				}}
			>
				{({
					getRootProps,
					acceptedFile,
					ProgressBar,
					getRemoveFileProps,
					Remove,
				}) => (
					<>
						<div
							{...getRootProps()}
							style={Object.assign(
								{},
								styles.zone,
								zoneHover && styles.zoneHover
							)}
						>
							{acceptedFile ? (
								<>
									<div style={styles.file}>
										<div style={styles.info}>
											<span style={styles.size}>
												{formatFileSize(acceptedFile.size)}
											</span>
											<span style={styles.name}>{acceptedFile.name}</span>
										</div>
										<div style={styles.progressBar}>
											<ProgressBar />
										</div>
										<div
											{...getRemoveFileProps()}
											style={styles.remove}
											onMouseOver={(event) => {
												event.preventDefault();
												setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
											}}
											onMouseOut={(event) => {
												event.preventDefault();
												setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
											}}
											onClick={(event) => {
												getRemoveFileProps().onClick(event);
												setUploadedMappings(null);
											}}
										>
											<Remove color={removeHoverColor} />
										</div>
									</div>
								</>
							) : (
								"Drop CSV file here or click to upload"
							)}
						</div>
					</>
				)}
			</CSVReader>

			<Button
				onClick={handleMappings}
				type="primary"
				style={{ marginTop: 50 }}
				disabled={uploadedMappings === null}
				loading={loading}
			>
				Save Mappings
			</Button>
		</>
	);
};

export default JobsMappingUpload;
