import React from 'react';
import { Button, Input } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { DateRangePicker } from 'react-date-range';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const AnalyticsHeader = ({ 
  mergedData, 
  pickerRef, 
  selectedRange, 
  togglePicker, 
  pickerOpen, 
  handleDateChange 
}) => {
  const exportToExcel = () => {
    const exportData = mergedData.map(item => ({
      'Organization': item.organization_name,
      'Total Unique Leads': item.total_unique_leads,
      'Total Bookings': item.total_bookings,
      'Total Abandonments': item.total_abandonments,
      'Booking Rate (%)': `${item.booking_rate_percentage.toFixed(2)}%`
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Analytics');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    saveAs(dataBlob, `analytics_${dayjs().format('YYYYMMDD_HHmmss')}.xlsx`);
  };

  return (
    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", marginBottom: '20px', gap: '16px' }}>
      <Button
        icon={<FileExcelOutlined />}
        onClick={exportToExcel}
        style={{
          backgroundColor: '#52c41a',
          color: '#fff',
          border: 'none',
        }}
      >
        Export to Excel
      </Button>
      <div style={{ width: '250px', position: 'relative' }} ref={pickerRef}>
        <Input
          className="w-full cursor-pointer"
          value={`${dayjs(selectedRange[0].startDate).format('YYYY-MM-DD')} - ${dayjs(selectedRange[0].endDate).format('YYYY-MM-DD')}`}
          onClick={togglePicker}
          readOnly
          size="small"
          style={{ fontSize: '14px', height: 30, textAlign: "center" }}
        />
        {pickerOpen && (
          <div 
            style={{ 
              position: 'absolute', 
              zIndex: 1000, 
              top: '100%', 
              right: 0,
              backgroundColor: 'white',
              boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
              borderRadius: '4px'
            }}
          >
            <DateRangePicker
              ranges={selectedRange}
              onChange={handleDateChange}
              months={2}
              direction="horizontal"
              preventSnapRefocus={true}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AnalyticsHeader; 