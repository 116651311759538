// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-picker-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .date-picker-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1000;
    background: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 10px;
    overflow: hidden;
    width: max-content;
  }
  
  .date-picker-dropdown.adjust-right {
    right: 0;
    left: auto;
  }
  
  .total-row {
    background-color: #f0f8ff;
    font-weight: bold;
  }
  
  .total-row:hover > td {
    background-color: #f0f0f0 !important;
  }
  
  .hover-row:hover {
    background-color: #f5f5f5;
  }
  
  /* Add styles for the source analysis tab */
  .source-analysis-container {
    margin-top: 20px;
  }
  
  .source-card {
    margin-bottom: 20px;
  }
  
  .source-chart-container {
    height: 400px;
    width: 100%;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Admin/Adminanalytics.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,aAAa;IACb,iBAAiB;IACjB,2CAA2C;IAC3C,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,QAAQ;IACR,UAAU;EACZ;;EAEA;IACE,yBAAyB;IACzB,iBAAiB;EACnB;;EAEA;IACE,oCAAoC;EACtC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA,2CAA2C;EAC3C;IACE,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,WAAW;EACb","sourcesContent":[".date-picker-container {\n    position: relative;\n    display: inline-block;\n    width: 100%;\n  }\n  \n  .date-picker-dropdown {\n    position: absolute;\n    top: 100%;\n    right: 0;\n    z-index: 1000;\n    background: white;\n    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);\n    border-radius: 4px;\n    padding: 10px;\n    overflow: hidden;\n    width: max-content;\n  }\n  \n  .date-picker-dropdown.adjust-right {\n    right: 0;\n    left: auto;\n  }\n  \n  .total-row {\n    background-color: #f0f8ff;\n    font-weight: bold;\n  }\n  \n  .total-row:hover > td {\n    background-color: #f0f0f0 !important;\n  }\n  \n  .hover-row:hover {\n    background-color: #f5f5f5;\n  }\n  \n  /* Add styles for the source analysis tab */\n  .source-analysis-container {\n    margin-top: 20px;\n  }\n  \n  .source-card {\n    margin-bottom: 20px;\n  }\n  \n  .source-chart-container {\n    height: 400px;\n    width: 100%;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
