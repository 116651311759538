import { useEffect } from "react";
import CreateCampaignMapping from "../../components/Campaigns/createCampaignMapping";
import { useDispatch, useSelector } from "react-redux";
import { fetchSTCampaigns } from "../../redux/organizationSlice";

const Campaigns = () => {
	const dispatch = useDispatch();
	const { currentOrganizationId } = useSelector((state) => state.auth);

	useEffect(() => {
		dispatch(fetchSTCampaigns(currentOrganizationId));
	}, [dispatch, currentOrganizationId]);

	
	return <CreateCampaignMapping />
};

export default Campaigns;
